/**
 * Dear future people who have been enlisted to work on this project,
 * I'm sorry.
 *
 * This was the first real React project I worked on.
 * In fact it was the first propper web project I worked on.
 * I could barley code in 2020 when I started this project,
 * and I made a lot of bad descions. Like, A LOT, a lot.
 * Over time, I've tried to make the best of a bad
 * situation, but the entire app is just strucured so
 * badyly that there's not much I can do without a
 * complete re-write.
 * If you look around the code, you'll see that the
 * file / page / component structure makes 0 sense.
 * That's because orginally every page was really
 * small, so I figured I could just stuff everything
 * into a couple of files, because that would mean
 * I wouldn't need to switch files as much. As the
 * project grew in size, my development speed started
 * decreasing dramtically, because I was spending so
 * much time searching around files for the bit that
 * I wanted to change. I started trying to split
 * things off into their own files, but it was a
 * futile effort - it just made an already slow
 * process even slower because of the amount of time
 * I spent trying to fix all the imports.
 *
 * Speaking of imports, you'll notice that in most
 * of the files, the imports take up at least 1
 * entire screen of space. That's because when
 * I started, I was not aware that Material-UI
 * exported all the components from the root,
 * so I imported each induvial component from it's
 * file. That was one of the biggest mistakes.
 * Now, whenever I have to import something
 * I import it propeprly, but for a long time I
 * kept importing every component from it's own
 * file to stay consistent. Whenev've I've had to make
 * a change recently, I've imported stuff properly,
 * in an effort to eventually convert the imports.
 * I don't think that has happened.
 *
 * Next, the build speeds. The app takes over
 * 30 secconds to build. Yes. You read that correctly.
 * That's because of two things:
 * 1. I was stupid and set it up to copy over a large
 * 	amount of images every single time it rebuilds.
 * 2. I didn't know that create-react-app existed when
 * 	I started wring the app. So I wrote the webpack
 *  configuration file myself. By looking up tutorials
 * 	online. Yep. So it's very slow. Also because I
 * 	didn't use CRA there was no hot reload at first.
 * 	At first I didn't know that hot reload existed,
 * 	so I happily waited for the enitre app to rebuild
 * 	every time I made a change. Yeah... Eventually
 * 	after working on some other React projects, I
 * 	learned about hot reload. Unfortuantley,
 * 	hot reaload is hard to set up. So I added a super
 * 	scuffed version (again, based on online tutorials).
 * 	It literally just watches for file changes, then
 * 	rebuilds the app and reloads the page. Sorry about
 * 	that.
 *
 * Last but not least, this app has a lot of
 * data being passed around. That means that if I
 * don't have assurences from the editor that I'm
 * accessing the correct property, I will eventually
 * mess somthing up. And I did. A lot. And because
 * the editor didn't warn me, I had to wait for the
 * app to compile then dig through errors in the browser
 * console and try to figure out what was wrong.
 * If I'd jsut used Typescript from the outset,
 * this could have been avoided. Whoops...
 *
 * Lastly, I need to mention that I didn't make the
 * web app alone. Ben helped out with a lot of it, but
 * unfortunetly I had already messed it up so badly
 * that he had no choice but to continue doing stuff in
 * the same terrible ways I had set everything up in.
 *
 * Again, I'm truly sorry for the mess I've left behind.
 * Please don't judge Ben or me based on anything you see
 * here. The app "works" and we just want to move on.
 * You may think me a coward for abandoning this huge
 * mess, and you would be correct. I just want to move
 * on with my life. If you end up improving this code,
 * please give me a call so that I can express my
 * gratitude and congratulations.
 *
 * I understand that Ben is writing a similar
 * message in the python code, so make sure
 * to read that as well.
 *
 * Please forgive me.
 * Zade Viggers.
 *
 * 27th August 2021
 *
 * PH (+64) 021 022 57619
 **/

/*
 * Things the web app is desperatly in need of:
 *	- Typescript.
 *	- Imports being refactored to take up less space.
 *  - The build process being fixed. Migrate to CRA?
 *	- A full re-write.
 */

import {
	ThemeProvider,
	unstable_createMuiStrictModeTheme as createMuiTheme,
	responsiveFontSizes,
} from "@material-ui/core/styles";

import Alert from "@material-ui/lab/Alert";
import AppContext from "./context/index.js";
import { Box } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Fade from "@material-ui/core/Fade";
import LoginPage from "./pages/login.js";
import React from "react";
import { HashRouter as Router } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import StudentDash from "./pages/studentdash";
import TeacherDash from "./pages/teacherdash";
import Update from "./components/update";
import { makeStyles } from "@material-ui/core/styles";

export const darkModeLocalStorageNamspace = "ReatilSimulaiton::DarkMode";

const useStyles = makeStyles((theme) => ({
	wrapper: {
		width: "100%",
		height: "100%",
		display: "flex",
	},
}));

export default function () {
	const classes = useStyles();
	var { user, setUser } = React.useContext(AppContext);
	const [error, setError] = React.useState("");
	const [severity, setSeverity] = React.useState("");
	const [open, setOpen] = React.useState(false);

	function makeAlert(error, severity) {
		setError(error);
		setSeverity(severity);
		setOpen(true);
	}

	window.makeAlert = makeAlert;

	const prefersDarkMode = matchMedia("(prefers-color-scheme: dark)").matches;
	const [darkMode, setDarkMode] = React.useState(prefersDarkMode);
	const darkModeEnabled = darkMode === "system" ? prefersDarkMode : darkMode;

	function setDarkModeAndDoTheLocalStorageThing(newValue) {
		if (newValue === "system") {
			localStorage.setItem(darkModeLocalStorageNamspace, "system");
			setDarkMode("system");
		} else {
			localStorage.setItem(darkModeLocalStorageNamspace, JSON.stringify(newValue));
			setDarkMode(newValue);
		}
	}

	React.useEffect(() => {
		const storedData = localStorage.getItem(darkModeLocalStorageNamspace);
		if (storedData) {
			if (storedData === "system") {
				setDarkModeAndDoTheLocalStorageThing("system");
			} else {
				setDarkModeAndDoTheLocalStorageThing(JSON.parse(storedData));
			}
		} else {
			setDarkModeAndDoTheLocalStorageThing("system");
		}

		// async function removeServiceWorkers() {
		// 	for (let i in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]) {
		// 		navigator.serviceWorker.getRegistrations().then(function (registrations) {
		// 			for (let registration of registrations) {
		// 				console.log("unregistering", registration)
		// 				registration.unregister()
		// 			}
		// 		}).catch(function (err) {
		// 			console.log('Service Worker registration failed: ', err);
		// 		});
		// 		await setTimeout(console.log, 1000)
		// 	}
		// }

		// removeServiceWorkers()
		// window.caches?.open("workbox-precache-v2-https://www.retailsimulation.co.nz/").then(function (cache) {
		// 	cache.delete("/index.html", { ignoreSearch: true });
		// });

		// window.caches?.open("workbox-precache-v2-https://retailsimulation.co.nz/").then(function (cache) {
		// 	cache.delete("/index.html", { ignoreSearch: true });
		// });
	}, []);

	// set html body to dark mode
	React.useEffect(() => {
		if (darkModeEnabled) {
			document.body.classList.add("dark");
		} else {
			document.body.classList.remove("dark");
		}
	}, [darkModeEnabled]);

	// const userLocalStorageNamespace = "WCommerceSignInPersistence"

	// if (!user) {
	// 	const storedUser = localStorage.getItem(userLocalStorageNamespace);
	// 	if (storedUser) {
	// 		const user = JSON.parse(storedUser);
	// 		if (user.token) {
	// 			setUser(user);
	// 		} else {
	// 			localStorage.setItem(userLocalStorageNamespace, "");
	// 		}
	// 	}
	// }

	let theme = createMuiTheme({
		palette: {
			type: darkModeEnabled ? "dark" : "light",
			primary: {
				main: "#222222",
			},
			secondary: {
				main: "#FCC000",
			},
		},
		props: {
			MuiTypography: {
				variantMapping: {
					h1: "h1",
					h2: "h2",
					h3: "h2",
					h4: "h3",
					h5: "h4",
					h6: "h4",
					subtitle1: "h5",
					subtitle2: "h5",
					body1: "span",
					body2: "span",
				},
			},
		},
	});

	theme = responsiveFontSizes(theme);

	return (
		<>
			<CssBaseline />
			<ThemeProvider theme={theme}>
				<Router>
					<Fade in timeout={500}>
						<Box height="100%">
							{user.token ? (
								user.teacher ? (
									<TeacherDash
										darkMode={darkModeEnabled}
										setDarkModeAndDoTheLocalStorageThing={setDarkModeAndDoTheLocalStorageThing}
									/>
								) : (
									<StudentDash
										darkMode={darkModeEnabled}
										setDarkModeAndDoTheLocalStorageThing={setDarkModeAndDoTheLocalStorageThing}
									/>
								)
							) : (
								<LoginPage />
							)}
						</Box>
					</Fade>
				</Router>
			</ThemeProvider>
			<Update />
			<Snackbar
				open={open}
				autoHideDuration={5000}
				onClose={() => {
					setOpen(false);
				}}
			>
				<Alert
					severity={severity}
					onClose={() => {
						setOpen(false);
					}}
				>
					{error}
				</Alert>
			</Snackbar>
		</>
	);
}
