import Autocomplete, {
	createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { Link as RouterLink, useHistory } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import AppContext from "../context";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import Brightness7Icon from "@material-ui/icons/Brightness7";
import Button from "@material-ui/core/Button";
import Classes from "./teacher/classes.js";
import DBBackups from "./teacher/db-backups.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import FeedbackIcon from "@material-ui/icons/Feedback";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import NProgress from "nprogress";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import preval from "preval.macro";
import MenuItem from "@material-ui/core/MenuItem";
import { Update as UpdateIcon } from "@material-ui/icons";
import MuiAlert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Rating from "@material-ui/lab/Rating";
import React from "react";
import SQLTerminal from "./teacher/sql-terminal.js";
import Snackbar from "@material-ui/core/Snackbar";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import { Tooltip } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import updateUserAndDoTheLocalstorageThing from "../utils/userLocalStorage.js";
import callAPI from "../utils/api.js";
import { Popover } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { List } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		minHeight: "100%",
		height: "auto",
		minHeight: "100%",
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 3,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	hide: {
		display: "none",
	},
	drawer: {
		zIndex: theme.zIndex.drawer + 2,
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: "nowrap",
	},
	drawerOpen: {
		zIndex: theme.zIndex.drawer + 2,
		width: drawerWidth,
		position: "fixed",
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		zIndex: theme.zIndex.drawer + 2,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: "hidden",
		position: "fixed",

		width: theme.spacing(6) + 1,
		[theme.breakpoints.up("sm")]: {
			width: theme.spacing(9) + 1,
		},
	},
	mobileDrawer: {
		width: drawerWidth,
	},
	toolbar: {
		width: "100%",
		paddingLeft: "30px",
		display: "flex",
	},
	toolbarButtons: {},
	toolbarButton: {
		marginRight: theme.spacing(2),
		marginLeft: theme.spacing(2),
		width: theme.spacing(5),
		color: theme.palette.primary.contrastText,
	},
	toggleDrawerButton: { marginRight: theme.spacing(1) },
	avatar: {
		width: theme.spacing(6),
		height: theme.spacing(6),
		marginRight: theme.spacing(1),
		marginLeft: theme.spacing(1),
		cursor: "pointer",
		border: "2px solid " + theme.palette.secondary.main,
	},
	toolbarRight: {
		display: "flex",
		alignItems: "center",
		marginLeft: "auto",
	},
	menuIcon: {
		marginLeft: 2,
		marginRight: theme.spacing(1),
	},
	drawerContainer: {
		overflow: "auto",
	},
	sidebar: {
		height: "100%",
	},
	skinnySidebar: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	nested: {
		paddingLeft: theme.spacing(4),
	},
	mainContent: {
		display: "flex",
		flexGrow: 1,
		backgroundColor: theme.palette.background.default,
		flexDirection: "column",
		width: "100%",
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
	},
	cssLabel: {
		color: "white",
	},

	cssOutlinedInput: {
		"&$cssFocused $notchedOutline": {
			borderColor: "white",
			color: "white",
		},
	},

	cssFocused: {
		color: "white",
	},

	notchedOutline: {
		borderColor: "white !important",
		color: "white",
	},

	whiteInput: {
		color: "white",
	},
	popover: {
		// background: theme.custom.palette.profilePopColor,
		// width: 200,
		// backgroundColor: "rgba(255, 255, 255, .15)",
		// backdropFilter: "blur(5px)",
		// backgroundColor: "rgba(100, 50, 180, 0.9)",
		// backgroundColor: "#3b0087"
		// borderRadius: theme.shape.borderRadius
	},
	container: {
		display: "flex",
		padding: theme.spacing(2),
		flexDirection: "column",
		alignItems: "center",
		width: 300,
	},
	avatar: {
		width: theme.spacing(6),
		height: theme.spacing(6),
		border: "2px solid " + theme.palette.secondary.main,
		margin: theme.spacing(1),
		// background: theme.palette.background.default
	},
	userInfo: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		paddingLeft: theme.spacing(1),
	},
	userName: {
		// ...theme.custom.fontFamily.metropolis,
		fontSize: "1rem",
		fontWeight: 500,
	},
	userEmail: {
		// ...theme.custom.fontFamily.roboto,
		fontSize: "0.9rem",
	},
	bar: {
		padding: theme.spacing(2),
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	buttonSignout: {
		// ...theme.custom.fontFamily.metropolis,
		borderRadius: theme.spacing(0.5),
		padding: theme.spacing(0.5, 2),
		fontSize: "0.8rem",
		fontWeight: 500,
		textTransform: "none",
	},
	textbox: {
		// ...theme.custom.fontFamily.metropolis,
		color: 'white'
	},
}));
export function SidebarMenuItem(props) {
	const {
		i,
		icon,
		text,
		route,
		selectedIndex,
		setSelectedIndex,
		setDrawerOpen,
	} = props;
	return (
		<ListItem
			button
			component={RouterLink}
			to={route}
			selected={selectedIndex === i}
			onClick={() => {
				setDrawerOpen(false);
				setSelectedIndex(i);
			}}
		>
			<ListItemIcon>{icon}</ListItemIcon>
			<ListItemText primary={text} />
		</ListItem>
	);
}

function Search(props) {
	const classes = useStyles();
	const { user, setUser } = React.useContext(AppContext);
	const { classData, classDataLoaded } = props;
	const history = useHistory();

	function switchUser(name) {
		NProgress.start();
		callAPI(
			"SignInAs",
			user.token,
			{ name: name },
			function (err, res, xhr) {
				NProgress.done();
				console.log(res.classes);
				if (!err) {
					var localUser = JSON.parse(
						localStorage.WCommerceSignInPersistence
					);
					localUser.token = res.token;
					localUser.firstName = res.firstName;
					localUser.teacher = false;
					window.localStorage.WCommerceSignInPersistence =
						JSON.stringify(localUser);
					window.location.reload();
				}
			}
		);
	}

	var students = {};
	if (classDataLoaded) {
		Object.keys(classData).map((className) =>
			Object.keys(classData[className].teams).map((team) =>
				JSON.parse(classData[className].teams[team].Members).map(
					(student) =>
					(students[student] = {
						team: team,
						className: className,
					})
				)
			)
		);
	}
	console.log("students", students);
	return (
		<Autocomplete
			style={{
				width: "50%",
				margin: "auto",
				marginLeft: "20px",
				marginRight: "20px",
				flexGrow: 4,
			}}
			size="small"
			options={Object.keys(students)}
			// filterOptions={createFilterOptions({ limit: 4 })}
			onChange={(event, value) =>
				history.push(
					"/" + students[value].className + "/" + students[value].team
				)
			}
			getOptionLabel={(option) => option}
			renderOption={(option) => (
				<React.Fragment>
					<Avatar src={"/static/images/students/" + option + ".jpeg"} />
					<Grid container style={{ marginLeft: "20px" }} spacing={3}>
						<Grid item xs={6}>
							{option}
							<Button
								onClick={() => {
									switchUser(option);
								}}
								color="primary"
								variant="outlined"
								style={{ float: "right", right: 30 }}
							>
								Sign in
							</Button>
						</Grid>
						<Grid item xs={3}>
							<Typography
								variant="button"
								display="block"
								gutterBottom
							>
								Class {students[option].className}
							</Typography>
						</Grid>
						<Grid item xs={3}>
							<Typography
								variant="button"
								display="block"
								gutterBottom
							>
								Team {students[option].team}
							</Typography>
						</Grid>
					</Grid>
				</React.Fragment>
			)}
			renderInput={(params) => (
				<TextField
					{...params}
					label="🔎   Search for students..."
					variant="outlined"
					style={{ color: "white" }}
					InputProps={{
						...params.InputProps,
						autoComplete: "new-password", // disable autocomplete and autofill
						classes: {
							input: classes.whiteInput,
							root: classes.cssOutlinedInput,
							focused: classes.cssFocused,
							notchedOutline: classes.notchedOutline,
						},
					}}
					InputLabelProps={{
						...params.inputLabelProps,
						style: { color: "white" },
					}}
				/>
			)}
		/>
	);
}

export default function TeacherDashboard(props) {
	const [showing, setShowing] = React.useState({
		terminal: false,
		backups: false,
	});

	const classes = useStyles();
	const { user, setUser } = React.useContext(AppContext);
	const { darkMode, setDarkModeAndDoTheLocalStorageThing } = props;
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [state, setState] = React.useState({ open: false });
	const [feedbackModalOpen, setFeedbackModalOpen] = React.useState(false);
	const [feedbackValues, setFeedbackValues] = React.useState({
		rating: 1,
		type: "feedback",
		text: "",
	});
	const [feedbackSuccessSnackbarOpen, setFeedbackSuccessSnackbarOpen] =
		React.useState(false);
	const [classData, setClassData] = React.useState({});
	const [classDataLoaded, setClassDataLoaded] = React.useState(false);

	//const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const [selectedIndex, setSelectedIndex] = React.useState(0);

	function openMenu(e) {
		setAnchorEl(e.currentTarget);
	}

	function closeMenu() {
		setAnchorEl(null);
	}

	function logOut() {
		closeMenu();
		setUser({});
		window.location.reload();
	}

	React.useEffect(() => {
		dataHasBeenUpdated();
	}, []);

	function dataHasBeenUpdated() {
		NProgress.start();
		callAPI("GetClassInfo", user.token, {}, function (err, res, xhr) {
			NProgress.done();
			console.log(res.classes);
			if (!err) {
				setClassData(res.classes);
				setClassDataLoaded(true);
			}
		});
	}

	function sendFeedbackToServer() {
		NProgress.start();
		callAPI(
			"SendFeedback",
			user.token,
			feedbackValues,
			function (err, res, xhr) {
				NProgress.done();
				if (!err) {
					setFeedbackSuccessSnackbarOpen(true);
				}
			}
		);
	}

	function openMenu(e) {
		setAnchorEl(e.currentTarget);
	}

	function closeMenu() {
		setAnchorEl(null);
	}

	React.useEffect(() => {
		document.title = "Dashboard - Retail Simulation";
	}, []);

	return (
		<div className={classes.root}>
			<AppBar position="fixed" className={classes.appBar}>
				<Toolbar className={classes.toolbar}>
					{/*<Hidden smUp implementation="js">
						<IconButton color="inherit" aria-label="open drawer" edge="start" onClick={setDrawOpen(true)}>
							<MenuIcon />
						</IconButton>
					</Hidden>*/}
					<Typography variant="h5">
						<Link
							variant="inherit"
							color="inherit"
							component={RouterLink}
							to={"/"}
							onClick={() => setSelectedIndex(0)}
						>
							Teacher dashboard
						</Link>
					</Typography>
					<Search
						classData={classData}
						classDataLoaded={classDataLoaded}
					/>
					<div className={classes.toolbarRight}>
						<Tooltip title="Send Feedback">
							<IconButton
								className={classes.toolbarButton}
								onClick={() => {
									setFeedbackModalOpen(true);
								}}
							>
								<FeedbackIcon />
							</IconButton>
						</Tooltip>
						<Avatar
							alt={user.fullName}
							src={user.imageUrl}
							className={classes.avatar}
							onClick={openMenu}
						/>
						{/* <Menu
							id="user-menu"
							anchorEl={anchorEl}
							keepMounted
							open={Boolean(anchorEl)}
							onClose={closeMenu}
						>
							<MenuItem
								onClick={() => {
									if (darkMode === "system") {
										setDarkModeAndDoTheLocalStorageThing(
											true
										);
									} else if (darkMode) {
										setDarkModeAndDoTheLocalStorageThing(
											false
										);
									} else {
										setDarkModeAndDoTheLocalStorageThing(
											"system"
										);
									}
								}}
							>
								<Brightness4Icon className={classes.menuIcon} />
								Dark mode:{" "}
								{darkMode === "system"
									? "System"
									: darkMode
										? "Enabled"
										: "Disabled"}
							</MenuItem>
							<MenuItem onClick={logOut}>
								<ExitToAppIcon className={classes.menuIcon} />
								Logout
							</MenuItem>
						</Menu> */}
						<Popover
							open={!!anchorEl}
							// id="frosted"
							anchorEl={anchorEl}
							onClose={closeMenu}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "right",
							}}
							transformOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
						>
							<div className={classes.container}>
								<Avatar
									alt={user.fullName}
									className={classes.avatar}
									src={user.imageUrl || user.picture}
									imgProps={{ style: { width: "100%" } }}
								// onLoad={(a) => (a.target.className = "animate")}
								></Avatar>
								<div className={classes.userInfo}>
									<Typography
										className={classes.userName}
										variant="h6"
										component="span"
										color="textPrimary"
									>
										{user.fullName}
									</Typography>
									<Typography
										className={classes.userEmail}
										variant="body1"
										component="span"
										color="textSecondary"
									>
										{user.email}
									</Typography>
								</div>
							</div>
							<Typography
								variant="caption"
								style={{
									float: "left",
									color: "rgb(0, 0, 0, 0.5)",
									marginLeft: "10px",
									marginTop: "-10px",
									marginBottom: "-4px",
								}}
							>
								Version:{" "}
								{preval`module.exports = new Date().toLocaleString();`}
							</Typography>
							<Divider />
							<List style={{ padding: "0 0 0 0" }}>
								<ListItem
									button
									onClick={() => {
										setFeedbackModalOpen(true);
									}}
								>
									<ListItemIcon>
										<FeedbackIcon />
									</ListItemIcon>
									<ListItemText primary="Send feedback" />
								</ListItem>
								{window.updateAvailable && <ListItem
									button
									onClick={() => {
										window.updateApp();
									}}
								>
									<ListItemIcon>
										<UpdateIcon />
									</ListItemIcon>
									<ListItemText primary="Update" />
								</ListItem>}
								<ListItem
									button
									onClick={() => setDarkModeAndDoTheLocalStorageThing(
										!darkMode
									)}
								>
									<ListItemIcon>
										<Checkbox
											// edge="start"
											style={{ marginLeft: -8 }}
											checked={!!darkMode}
										/>
									</ListItemIcon>
									<ListItemText primary="Dark mode" />
								</ListItem>
							</List>
							<Divider />
							<div className={classes.bar}>
								<Button
									variant="outlined"
									size="small"
									onClick={logOut}
									classes={{ root: classes.buttonSignout }}
								>
									Sign out
								</Button>
								{/* <Button variant="outlined" size="small" onClick={refreshPermissions} classes={{ root: classes.buttonPermissions }}>Refresh permissions</Button> */}
							</div>
						</Popover>
					</div>
				</Toolbar>
			</AppBar>
			{showing.backups ? (
				<DBBackups />
			) : showing.terminal ? (
				<SQLTerminal />
			) : (
				<Paper square elevation={0} className={classes.mainContent}>
					<Toolbar position="fixed" />
					<Classes
						classData={classData}
						classDataLoaded={classDataLoaded}
						dataUpdated={dataHasBeenUpdated}
					/>
				</Paper>
			)}

			<Dialog
				open={feedbackModalOpen}
				onClose={() => {
					setFeedbackModalOpen(false);
				}}
			>
				<DialogTitle id="form-dialog-title">
					{feedbackValues.type === "feedback"
						? "Send feedback about Retail Simulation"
						: "Report an issue with Retail Simulation"}
				</DialogTitle>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						setFeedbackModalOpen(false);
						sendFeedbackToServer();
					}}
				>
					<FormControl>
						<DialogContent>
							<DialogContentText>
								We're working hard on making Retail Simulation
								the best possible experience for you
								{feedbackValues.type === "feedback"
									? " and we would love to hear what you think about it!"
									: ". To help us fix it as soon as possible, please describe the issue in detail and explain how you encountered it."}
								{feedbackValues.type === "feedback"
									? null
									: !user.teacher
										? " Please only report issues with the website here. If some numbers are wrong, please talk to your teacher."
										: null}
							</DialogContentText>
							<TextField
								margin="dense"
								id="feedback-message"
								label={
									feedbackValues.type === "feedback"
										? "Feedback"
										: "Describe the issue"
								}
								placeholder={
									feedbackValues.type === "feedback"
										? "I love Retail Simulation because the website looks so dang good."
										: "I'm having a problem with... [describe what the problem is with]. I can see... [describe what you can see]. I think that I should be seeing... [describe what you whink you should be seeing]. To make this happen I... [list of steps to replicate the problem]."
								}
								variant="filled"
								color="secondary"
								multiline={true}
								rows={3}
								fullWidth={true}
								value={feedbackValues.message}
								onChange={(e) => {
									setFeedbackValues({
										...feedbackValues,
										text: e.target.value,
									});
								}}
								required={true}
							/>
							<Box
								m={0}
								borderColor="transparent"
								component="fieldset"
							>
								<Typography component="legend">
									{feedbackValues.type === "feedback"
										? "Rate your experience with Retail Simulation"
										: "How urgent is your issue?"}
								</Typography>
								<Rating
									name="feedback-rating"
									size="large"
									value={feedbackValues.rating}
									onChange={(e, newValue) => {
										// newValue only works in this component btw
										setFeedbackValues({
											...feedbackValues,
											rating: newValue,
										});
									}}
									required={true}
								/>
							</Box>
							<FormControlLabel
								control={
									<Switch
										checked={
											feedbackValues.type === "request"
												? true
												: false
										}
										onChange={(e) => {
											setFeedbackValues({
												...feedbackValues,
												type: e.target.checked
													? "request"
													: "feedback",
											});
										}}
										name="feedback-request-toggle"
									/>
								}
								label="Support request"
							/>
						</DialogContent>
						<DialogActions>
							<Button
								onClick={() => {
									setFeedbackModalOpen(false);
								}}
								color="primary"
								variant="outlined"
							>
								Cancel
							</Button>
							<Button
								onClick={() => {
									setFeedbackModalOpen(false);
									sendFeedbackToServer();
								}}
								variant="contained"
								color="secondary"
								disabled={
									feedbackValues.text.length < 5 ||
									feedbackValues.text.length > 600
								}
							>
								Submit
							</Button>
						</DialogActions>
					</FormControl>
				</form>
			</Dialog>
			<Snackbar
				autoHideDuration={2500}
				onClose={() => setFeedbackSuccessSnackbarOpen(false)}
				open={feedbackSuccessSnackbarOpen}
			>
				<MuiAlert elevation={6} variant="filled" severity="success">
					{feedbackValues.type === "feedback"
						? "Feedback "
						: "Support request "}
					submitted!
				</MuiAlert>
			</Snackbar>
			<Snackbar
				autoHideDuration={2500}
				onClose={() => setState({ open: false })}
				open={state.open}
			>
				<MuiAlert elevation={6} variant="filled" severity="success">
					Successfully logged in as a teacher!
				</MuiAlert>
			</Snackbar>
		</div>
	);
}
