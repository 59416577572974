import { Box, InputAdornment, TextField } from "@material-ui/core";
import { DataGrid, convertRowsPropToState } from "@mui/x-data-grid";
import Figure, { FigureRow } from "../../components/figure.js";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import {
	Redirect,
	Route,
	Switch as RouterSwitch,
	useParams,
	useRouteMatch,
} from "react-router-dom";
import { Link as RouterLink, useHistory } from "react-router-dom";

import Alert from "@material-ui/lab/Alert";
import AppContext from "../../context";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Button from "@material-ui/core/Button";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import DateFnsUtils from "@date-io/date-fns";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Fab from "@material-ui/core/Fab";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import LoadingText from "../../components/loading.js";
import MenuItem from "@material-ui/core/MenuItem";
import MuiAlert from "@material-ui/lab/Alert";
import NumberSelector from "../../components/number-selector.js";
import Paper from "@material-ui/core/Paper";
import NProgress from "nprogress";
import PersonPicker from "../../components/person-picker.js";
import React from "react";
import Select from "@material-ui/core/Select";
import SimpleTable from "../../components/simple-table.js";
import Snackbar from "@material-ui/core/Snackbar";
import Switch from "@material-ui/core/Switch";
import Tab from "@material-ui/core/Tab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tabs from "@material-ui/core/Tabs";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import formatNumber from "../../utils/format-number";
import { makeStyles } from "@material-ui/core/styles";
import callAPI from "../../utils/api.js";
import v from "@material-ui/lab/Alert";

const months = [
	"Not started",
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

const initialLocations = {
	"NT. Little shop": {
		Class: "Initial",
		Name: "NT. Little shop",
		Rent: 2000,
		Bond: 6000,
		Sales_Modifier: 1,
		Storage_Capacity: 1000,
		Cost_of_Fittings: 25000,
		Staff_Capacity: 2,
	},
	"NT. Medium shop": {
		Class: "Initial",
		Name: "NT. Medium shop",
		Rent: 4000,
		Bond: 12000,
		Sales_Modifier: 1,
		Storage_Capacity: 2000,
		Cost_of_Fittings: 30000,
		Staff_Capacity: 4,
	},
	"NT. Large shop": {
		Class: "Initial",
		Name: "NT. Large shop",
		Rent: 6000,
		Bond: 18000,
		Sales_Modifier: 1,
		Storage_Capacity: 4000,
		Cost_of_Fittings: 35000,
		Staff_Capacity: 5,
	},
	"LQ. Very little shop": {
		Class: "Initial",
		Name: "LQ. Very little shop",
		Rent: 2000,
		Bond: 6000,
		Sales_Modifier: 1.25,
		Storage_Capacity: 600,
		Cost_of_Fittings: 20000,
		Staff_Capacity: 1,
	},
	"LQ. Little shop": {
		Class: "Initial",
		Name: "LQ. Little shop",
		Rent: 4000,
		Bond: 12000,
		Sales_Modifier: 1.25,
		Storage_Capacity: 1000,
		Cost_of_Fittings: 25000,
		Staff_Capacity: 2,
	},
	"LQ. Medium shop": {
		Class: "Initial",
		Name: "LQ. Medium shop",
		Rent: 8000,
		Bond: 24000,
		Sales_Modifier: 1.25,
		Storage_Capacity: 2000,
		Cost_of_Fittings: 30000,
		Staff_Capacity: 4,
	},
	"QG. Little shop": {
		Class: "Initial",
		Name: "QG. Little shop",
		Rent: 3000,
		Bond: 9000,
		Sales_Modifier: 1.1,
		Storage_Capacity: 1000,
		Cost_of_Fittings: 25000,
		Staff_Capacity: 2,
	},
	"QG. Medium shop": {
		Class: "Initial",
		Name: "QG. Medium shop",
		Rent: 6000,
		Bond: 18000,
		Sales_Modifier: 1.1,
		Storage_Capacity: 2000,
		Cost_of_Fittings: 30000,
		Staff_Capacity: 4,
	},
	"QG. Large shop": {
		Class: "Initial",
		Name: "QG. Large shop",
		Rent: 9000,
		Bond: 27000,
		Sales_Modifier: 1.1,
		Storage_Capacity: 4000,
		Cost_of_Fittings: 35000,
		Staff_Capacity: 5,
	},
	"CB. Very little shop": {
		Class: "Initial",
		Name: "CB. Very little shop",
		Rent: 1500,
		Bond: 4500,
		Sales_Modifier: 1.1,
		Storage_Capacity: 600,
		Cost_of_Fittings: 20000,
		Staff_Capacity: 1,
	},
	"CB. Little shop": {
		Class: "Initial",
		Name: "CB. Little shop",
		Rent: 3000,
		Bond: 9000,
		Sales_Modifier: 1.1,
		Storage_Capacity: 1000,
		Cost_of_Fittings: 25000,
		Staff_Capacity: 2,
	},
	"AP. Medium shop": {
		Class: "Initial",
		Name: "AP. Medium shop",
		Rent: 6000,
		Bond: 18000,
		Sales_Modifier: 1.1,
		Storage_Capacity: 2000,
		Cost_of_Fittings: 30000,
		Staff_Capacity: 4,
	},
	"ID. Large shop 1": {
		Class: "Initial",
		Name: "ID. Large shop 1",
		Rent: 5000,
		Bond: 15000,
		Sales_Modifier: 0.8,
		Storage_Capacity: 4000,
		Cost_of_Fittings: 35000,
		Staff_Capacity: 5,
	},
	"ID. Large shop 2": {
		Class: "Initial",
		Name: "ID. Large shop 2",
		Rent: 5000,
		Bond: 15000,
		Sales_Modifier: 0.8,
		Storage_Capacity: 4000,
		Cost_of_Fittings: 35000,
		Staff_Capacity: 5,
	},
	"ID. Very Large shop": {
		Class: "Initial",
		Name: "ID. Very Large shop",
		Rent: 8000,
		Bond: 24000,
		Sales_Modifier: 0.8,
		Storage_Capacity: 8000,
		Cost_of_Fittings: 40000,
		Staff_Capacity: 7,
	},
};

const useStyles = makeStyles((theme) => ({
	classPage: {
		paddingTop: theme.spacing(8),
		paddingLeft: theme.spacing(8),
		paddingRight: theme.spacing(8),
	},
	storePage: {
		paddingTop: theme.spacing(2),
		paddingLeft: theme.spacing(6),
		paddingRight: theme.spacing(6),
	},
	classSection: {
		marginTop: theme.spacing(3),
	},
	classSectionItem: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	smallAvatar: {
		width: theme.spacing(5),
		height: theme.spacing(5),
	},
	fab: {
		position: "fixed",
		bottom: theme.spacing(2),
		right: theme.spacing(2),
	},
	clasSectionPaper: {
		padding: theme.spacing(2),
		width: "95%",
	},
	classSettingInput: {
		minWidth: 160,
		margin: {
			top: theme.spacing(1),
			bottom: theme.spacing(1),
			right: theme.spacing(2),
		},
	},
	tabPage: {
		height: "100%;",
	},
	tabs: {
		color: theme.palette.type === "dark" ? "white" : "black",
	}
}));

export function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && children}
		</div>
	);
}

// This is what appears when you select a team from a class
export function StoreDetails(props) {
	const classes = useStyles();
	const { user, setUser } = React.useContext(AppContext);
	const { classData, teamData } = props;
	const history = useHistory();
	const { path, url } = useRouteMatch();
	const { storeNumber } = useParams();
	const storeData = teamData.stores[storeNumber];
	const [tab, setTab] = React.useState(
		Math.max(...Object.keys(storeData.outcomes)) -
		Math.min(...Object.keys(storeData.outcomes))
	);
	const month = tab + Math.min(...Object.keys(storeData.outcomes));

	const products = {
		"B. Mens Casual": {
			Product: "B. Mens Casual",
			Minimum_Order: 50,
			Cost_per_item: 10,
			RRP: 20,
			Demand: 4477,
		},
		"B. Mens Formal": {
			Product: "B. Mens Formal",
			Minimum_Order: 20,
			Cost_per_item: 20,
			RRP: 40,
			Demand: 707,
		},
		"B. Womens Casual": {
			Product: "B. Womens Casual",
			Minimum_Order: 50,
			Cost_per_item: 10,
			RRP: 20,
			Demand: 5632,
		},
		"B. Womens Formal": {
			Product: "B. Womens Formal",
			Minimum_Order: 20,
			Cost_per_item: 20,
			RRP: 40,
			Demand: 895,
		},
		"B. Boys": {
			Product: "B. Boys",
			Minimum_Order: 50,
			Cost_per_item: 5,
			RRP: 10,
			Demand: 1414,
		},
		"B. Girls": {
			Product: "B. Girls",
			Minimum_Order: 50,
			Cost_per_item: 5,
			RRP: 10,
			Demand: 1532,
		},
		"D. Mens Casual": {
			Product: "D. Mens Casual",
			Minimum_Order: 25,
			Cost_per_item: 40,
			RRP: 84,
			Demand: 1602,
		},
		"D. Mens Formal": {
			Product: "D. Mens Formal",
			Minimum_Order: 10,
			Cost_per_item: 80,
			RRP: 168,
			Demand: 778,
		},
		"D. Womens Casual": {
			Product: "D. Womens Casual",
			Minimum_Order: 25,
			Cost_per_item: 40,
			RRP: 84,
			Demand: 2074,
		},
		"D. Womens Formal": {
			Product: "D. Womens Formal",
			Minimum_Order: 10,
			Cost_per_item: 80,
			RRP: 168,
			Demand: 990,
		},
		"D. Boys": {
			Product: "D. Boys",
			Minimum_Order: 25,
			Cost_per_item: 25,
			RRP: 52.5,
			Demand: 471,
		},
		"D. Girls": {
			Product: "D. Girls",
			Minimum_Order: 25,
			Cost_per_item: 25,
			RRP: 52.5,
			Demand: 589,
		},
		"O. Mens": {
			Product: "O. Mens",
			Minimum_Order: 20,
			Cost_per_item: 50,
			RRP: 120,
			Demand: 1390,
		},
		"O. Womens": {
			Product: "O. Womens",
			Minimum_Order: 20,
			Cost_per_item: 50,
			RRP: 120,
			Demand: 1249,
		},
		"O. Kids": {
			Product: "O. Kids",
			Minimum_Order: 20,
			Cost_per_item: 25,
			RRP: 60,
			Demand: 1202,
		},
		None: {
			Product: "None",
			Minimum_Order: 0,
			Cost_per_item: 0,
			RRP: 0,
			Demand: 0,
		},
	};

	const className = classData.class_details.Name;

	if (!storeNumber) {
		return <Redirect to={"/" + className + "/" + teamData.team} />;
	}

	const handleChange = (event, newValue) => {
		setTab(newValue);
	};

	console.log(
		"max month",
		Math.max(...Object.keys(storeData.outcomes)),
		"month",
		month,
		"data",
		storeData
	);
	return (
		<div>
			{/* <Typography variant="h4">10CMM {className} store #{storeNumber}</Typography> */}
			<Tabs value={tab} onChange={handleChange} classes={{ root: classes.tabs }}>
				{Object.keys(storeData.outcomes).map((month) =>
					month === Math.min(...Object.keys(storeData.outcomes)) ? (
						<Tab label="Startup" />
					) : (
						<Tab label={months[month]} />
					)
				)}
			</Tabs>
			{month === Math.min(...Object.keys(storeData.outcomes)) ? (
				<div style={{ marginLeft: "20px", marginRight: "20px" }}>
					<Paper style={{ paddingTop: "1px", width: "80%" }}>
						{/* <Divider /> */}
						<Typography variant="h6" style={{ margin: "10px" }}>
							Startup info
						</Typography>
						<Divider />
						<Table>
							<TableRow>
								<TableCell>Starting capital</TableCell>
								<TableCell align="right">
									{formatNumber(
										storeData.outcomes[month].Bank -
										storeData.outcomes[month].Loan +
										storeData.location.Bond +
										storeData.location.Cost_of_Fittings,
										true
									)}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Starting loan</TableCell>
								<TableCell align="right">
									{formatNumber(
										storeData.outcomes[month].Loan,
										true
									)}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Location</TableCell>
								<TableCell align="right">
									{storeData.location.Name}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Bond</TableCell>
								<TableCell align="right">
									{formatNumber(
										storeData.location.Bond,
										true
									)}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Fixtures and fittings</TableCell>
								<TableCell align="right">
									{formatNumber(
										storeData.location.Cost_of_Fittings,
										true
									)}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Bank</TableCell>
								<TableCell align="right">
									{formatNumber(
										storeData.outcomes[month].Bank,
										true
									)}
								</TableCell>
							</TableRow>
						</Table>
					</Paper>
				</div>
			) : (
				<div style={{ marginLeft: "20px", marginRight: "20px" }}>
					<Grid container spacing={3}>
						<Grid item md={12} lg={7}>
							<Paper style={{ paddingTop: "1px" }}>
								<Typography
									variant="h6"
									style={{ margin: "10px" }}
								>
									Products
								</Typography>
								<Divider />
								<Table size="small">
									<TableHead>
										<TableRow>
											<TableCell width="25%">
												Product
											</TableCell>
											<TableCell
												width="10%"
												align="right"
											>
												Purchases
											</TableCell>
											<TableCell width="8%" align="right">
												Price
											</TableCell>
											<TableCell width="8%" align="right">
												Stock
											</TableCell>
											<TableCell width="8%" align="right">
												Sales
											</TableCell>
											<TableCell
												width="32%"
												align="right"
											>
												Remaining stock
											</TableCell>
											<TableCell width="9%" align="right">
												Revenue
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow>
											<TableCell
												component="th"
												scope="row"
											>
												{
													storeData.outcomes[month]
														.Product1
												}
											</TableCell>
											<TableCell align="right">
												{
													storeData.decisions[month]
														.ProductPurchases1
												}
											</TableCell>
											<TableCell align="right">
												{Math.round(
													storeData.decisions[month]
														.ProductPrice1 * 100
												).toString() + "%"}
											</TableCell>
											<TableCell align="right">
												{storeData.outcomes[month]
													.ProductStock1 +
													storeData.outcomes[month]
														.ProductSales1}
											</TableCell>
											<TableCell align="right">
												{
													storeData.outcomes[month]
														.ProductSales1
												}
											</TableCell>
											<TableCell align="right">
												{
													storeData.outcomes[month]
														.ProductStock1
												}
											</TableCell>
											<TableCell align="right">
												{formatNumber(
													storeData.outcomes[month]
														.ProductSales1 *
													products[
														storeData.outcomes[
															month
														].Product1
													].RRP *
													storeData.decisions[
														month
													].ProductPrice1,
													true
												)}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell
												component="th"
												scope="row"
											>
												{
													storeData.outcomes[month]
														.Product2
												}
											</TableCell>
											<TableCell align="right">
												{
													storeData.decisions[month]
														.ProductPurchases2
												}
											</TableCell>
											<TableCell align="right">
												{Math.round(
													storeData.decisions[month]
														.ProductPrice2 * 100
												).toString() + "%"}
											</TableCell>
											<TableCell align="right">
												{storeData.outcomes[month]
													.ProductStock2 +
													storeData.outcomes[month]
														.ProductSales2}
											</TableCell>
											<TableCell align="right">
												{
													storeData.outcomes[month]
														.ProductSales2
												}
											</TableCell>
											<TableCell align="right">
												{
													storeData.outcomes[month]
														.ProductStock2
												}
											</TableCell>
											<TableCell align="right">
												{formatNumber(
													storeData.outcomes[month]
														.ProductSales2 *
													products[
														storeData.outcomes[
															month
														].Product2
													].RRP *
													storeData.decisions[
														month
													].ProductPrice2,
													true
												)}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell
												component="th"
												scope="row"
											>
												{
													storeData.outcomes[month]
														.Product3
												}
											</TableCell>
											<TableCell align="right">
												{
													storeData.decisions[month]
														.ProductPurchases3
												}
											</TableCell>
											<TableCell align="right">
												{Math.round(
													storeData.decisions[month]
														.ProductPrice3 * 100
												).toString() + "%"}
											</TableCell>
											<TableCell align="right">
												{storeData.outcomes[month]
													.ProductStock3 +
													storeData.outcomes[month]
														.ProductSales3}
											</TableCell>
											<TableCell align="right">
												{
													storeData.outcomes[month]
														.ProductSales3
												}
											</TableCell>
											<TableCell align="right">
												{
													storeData.outcomes[month]
														.ProductStock3
												}
											</TableCell>
											<TableCell align="right">
												{formatNumber(
													storeData.outcomes[month]
														.ProductSales3 *
													products[
														storeData.outcomes[
															month
														].Product3
													].RRP *
													storeData.decisions[
														month
													].ProductPrice3,
													true
												)}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell
												component="th"
												scope="row"
											>
												{
													storeData.outcomes[month]
														.Product4
												}
											</TableCell>
											<TableCell align="right">
												{
													storeData.decisions[month]
														.ProductPurchases4
												}
											</TableCell>
											<TableCell align="right">
												{Math.round(
													storeData.decisions[month]
														.ProductPrice4 * 100
												).toString() + "%"}
											</TableCell>
											<TableCell align="right">
												{storeData.outcomes[month]
													.ProductStock4 +
													storeData.outcomes[month]
														.ProductSales4}
											</TableCell>
											<TableCell align="right">
												{
													storeData.outcomes[month]
														.ProductSales4
												}
											</TableCell>
											<TableCell align="right">
												{
													storeData.outcomes[month]
														.ProductStock4
												}
											</TableCell>
											<TableCell align="right">
												{formatNumber(
													storeData.outcomes[month]
														.ProductSales4 *
													products[
														storeData.outcomes[
															month
														].Product4
													].RRP *
													storeData.decisions[
														month
													].ProductPrice4,
													true
												)}
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</Paper>
							<Paper
								style={{ paddingTop: "1px", marginTop: "10px" }}
							>
								<Typography
									variant="h6"
									style={{ margin: "10px" }}
								>
									Statement of Financial Position as at 30th{" "}
									{months[month]}
								</Typography>
								<Divider />
								<Table size="small">
									<TableRow>
										<TableCell>Bank</TableCell>
										<TableCell align="right">
											{formatNumber(
												storeData.outcomes[month].Bank,
												true
											)}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Inventory</TableCell>
										<TableCell align="right">
											{formatNumber(
												storeData.outcomes[month]
													.ProductStock1 *
												products[
													storeData.outcomes[
														month
													].Product1
												].Cost_per_item +
												storeData.outcomes[month]
													.ProductStock2 *
												products[
													storeData.outcomes[
														month
													].Product2
												].Cost_per_item +
												storeData.outcomes[month]
													.ProductStock3 *
												products[
													storeData.outcomes[
														month
													].Product3
												].Cost_per_item +
												storeData.outcomes[month]
													.ProductStock4 *
												products[
													storeData.outcomes[
														month
													].Product4
												].Cost_per_item,
												true
											)}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Bond</TableCell>
										<TableCell align="right">
											{formatNumber(
												storeData.location.Bond,
												true
											)}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>
											Fixtures and fittings
										</TableCell>
										<TableCell align="right">
											{formatNumber(
												storeData.location
													.Cost_of_Fittings,
												true
											)}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>
											<Typography
												variant="button"
												display="block"
												gutterBottom
											>
												Total assets
											</Typography>
										</TableCell>
										<TableCell align="right">
											{formatNumber(
												storeData.outcomes[month].Bank +
												storeData.outcomes[month]
													.ProductStock1 *
												products[
													storeData.outcomes[
														month
													].Product1
												].Cost_per_item +
												storeData.outcomes[month]
													.ProductStock2 *
												products[
													storeData.outcomes[
														month
													].Product2
												].Cost_per_item +
												storeData.outcomes[month]
													.ProductStock3 *
												products[
													storeData.outcomes[
														month
													].Product3
												].Cost_per_item +
												storeData.outcomes[month]
													.ProductStock4 *
												products[
													storeData.outcomes[
														month
													].Product4
												].Cost_per_item +
												storeData.location.Bond +
												storeData.location
													.Cost_of_Fittings,
												true
											)}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Loan</TableCell>
										<TableCell align="right">
											{formatNumber(
												-1 * storeData.outcomes[month].Loan,
												true
											)}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>
											<Typography
												variant="button"
												display="block"
												gutterBottom
											>
												Net assets
											</Typography>
										</TableCell>
										<TableCell align="right">
											{formatNumber(
												storeData.outcomes[month].Bank +
												storeData.outcomes[month]
													.ProductStock1 *
												products[
													storeData.outcomes[
														month
													].Product1
												].Cost_per_item +
												storeData.outcomes[month]
													.ProductStock2 *
												products[
													storeData.outcomes[
														month
													].Product2
												].Cost_per_item +
												storeData.outcomes[month]
													.ProductStock3 *
												products[
													storeData.outcomes[
														month
													].Product3
												].Cost_per_item +
												storeData.outcomes[month]
													.ProductStock4 *
												products[
													storeData.outcomes[
														month
													].Product4
												].Cost_per_item +
												storeData.location.Bond +
												storeData.location
													.Cost_of_Fittings -
												storeData.outcomes[month]
													.Loan,
												true
											)}
										</TableCell>
									</TableRow>
								</Table>
							</Paper>
						</Grid>
						<Grid item md={12} lg={5}>
							<Paper
								style={{ paddingTop: "1px", height: "100%" }}
							>
								<Typography
									variant="h6"
									style={{ margin: "10px" }}
								>
									Statement of Financial Performance
								</Typography>
								<Divider />
								<Table size="small">
									<TableHead>
										<TableRow>
											<TableCell>Cost</TableCell>
											<TableCell align="right">
												Note
											</TableCell>
											<TableCell align="right">
												Amount
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow>
											<TableCell
												component="th"
												scope="row"
											>
												Revenue
											</TableCell>
											<TableCell align="right">

											</TableCell>
											<TableCell align="right">
												{formatNumber(
													storeData.breakdowns[month]
														.revenue,
													true
												)}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell
												component="th"
												scope="row"
											>
												Purchases
											</TableCell>
											<TableCell align="right">

											</TableCell>
											<TableCell align="right">
												{formatNumber(
													-1 * storeData.breakdowns[month]
														.purchases,
													true
												)}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell
												component="th"
												scope="row"
											>
												<Typography
													variant="button"
													display="block"
													gutterBottom
												>
													COGS
												</Typography>
											</TableCell>
											<TableCell align="right">

											</TableCell>
											<TableCell align="right">
												{formatNumber(
													-1 * storeData.breakdowns[month]
														.cogs,
													true
												)}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell
												component="th"
												scope="row"
											>
												<Typography
													variant="button"
													display="block"
													gutterBottom
												>
													Gross Profit
												</Typography>
											</TableCell>
											<TableCell align="right">

											</TableCell>
											<TableCell align="right">
												{formatNumber(
													storeData.breakdowns[month]
														.grossProfit,
													true
												)}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell
												component="th"
												scope="row"
											>
												Bank interest
											</TableCell>
											<TableCell align="right">

											</TableCell>
											<TableCell align="right">
												{formatNumber(
													storeData.breakdowns[month]
														.bankInterest,
													true
												)}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell
												component="th"
												scope="row"
											>
												Loan interest
											</TableCell>
											<TableCell align="right">

											</TableCell>
											<TableCell align="right">
												{formatNumber(
													-1 * storeData.breakdowns[month]
														.loanInterest,
													true
												)}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell
												component="th"
												scope="row"
											>
												Bank modifier
											</TableCell>
											<TableCell align="right">

											</TableCell>
											<TableCell align="right">
												{formatNumber(
													storeData.breakdowns[month]
														.drawings,
													true
												)}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell
												component="th"
												scope="row"
											>
												Advertising
											</TableCell>
											<TableCell align="right">
												{
													storeData.decisions[month]
														.Promotion
												}
											</TableCell>
											<TableCell align="right">
												{formatNumber(
													-1 * storeData.breakdowns[month]
														.advertising,
													true
												)}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell
												component="th"
												scope="row"
											>
												Rent
											</TableCell>
											<TableCell align="right">

											</TableCell>
											<TableCell align="right">
												{formatNumber(
													-1 * storeData.breakdowns[month]
														.rent,
													true
												)}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell
												component="th"
												scope="row"
											>
												Office Expenses
											</TableCell>
											<TableCell align="right">

											</TableCell>
											<TableCell align="right">
												{formatNumber(
													-1 * storeData.breakdowns[month]
														.officeExpenses,
													true
												)}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell
												component="th"
												scope="row"
											>
												Wages
											</TableCell>
											<TableCell align="right">
												{
													storeData.decisions[month]
														.Wage
												}{" "}
												wage for{" "}
												{
													storeData.decisions[month]
														.Staff
												}{" "}
												staff
											</TableCell>
											<TableCell align="right">
												{formatNumber(
													-1 * storeData.breakdowns[month]
														.wages,
													true
												)}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell
												component="th"
												scope="row"
											>
												<Typography
													variant="button"
													display="block"
													gutterBottom
												>
													Total expenses
												</Typography>
											</TableCell>
											<TableCell align="right">

											</TableCell>
											<TableCell align="right">
												{formatNumber(
													-1 * storeData.breakdowns[month]
														.expenses,
													true
												)}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell
												component="th"
												scope="row"
											>
												<Typography
													variant="button"
													display="block"
													gutterBottom
												>
													Net Profit
												</Typography>
											</TableCell>
											<TableCell align="right">

											</TableCell>
											<TableCell align="right">
												{formatNumber(
													storeData.breakdowns[month]
														.netProfit,
													true
												)}
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</Paper>
						</Grid>
					</Grid>
				</div>
			)}
		</div>
	);
}

function NewStore(props) {
	const [error, setError] = React.useState("");
	const [severity, setSeverity] = React.useState("");
	const [snackbarOpen, setSnackbarOpen] = React.useState(false);
	const [newStoreLoan, setNewStoreLoan] = React.useState(0);
	const [newStoreCapital, setNewStoreCapital] = React.useState(0);
	const [newStoreLocation, setNewStoreLocation] = React.useState();
	const { user, setUser } = React.useContext(AppContext);
	const { onClose, open, teamNumber, className, teamDataUpdated, locations } =
		props;

	function createStore() {
		NProgress.start();
		callAPI(
			"AddStore",
			user.token,
			{
				["class"]: className,
				team: teamNumber,
				location: newStoreLocation,
				startup_capital: newStoreCapital,
				loan: newStoreLoan,
			},
			function (err, res, xhr) {
				NProgress.done();
				console.log(res.classes);
				if (!err) {
					setError("Store created!");
					setSeverity("success");
					setSnackbarOpen(true);
					teamDataUpdated();
					return;
				} else {
					setError(
						"Couldn't create store due to an error. The developers have been notified."
					);
					setSeverity("error");
					setSnackbarOpen(true);
					return;
				}
			}
		);
	}

	return (
		<div>
			<Dialog open={open} onClose={onClose}>
				<DialogTitle>
					Create a new store for team {teamNumber}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Enter the required values below, and a new store will be
						created that is instantly available to students.
						<br />
						<br />
						Note: students will be able to transfer money between
						their stores from their own dashboard in the Bank tab.
					</DialogContentText>
					<CurrencyTextField
						label="Loan"
						variant="standard"
						value={newStoreLoan}
						currencySymbol="$"
						outputFormat="string"
						style={{ width: "100%", marginBottom: "20px" }}
						onChange={(event, value) => setNewStoreLoan(value)}
					/>
					<br />
					<CurrencyTextField
						label="Starting capital"
						variant="standard"
						value={newStoreCapital}
						currencySymbol="$"
						outputFormat="string"
						style={{ width: "100%", marginBottom: "20px" }}
						onChange={(event, value) => setNewStoreCapital(value)}
					/>
					<br />
					<FormControl style={{ width: "100%" }}>
						<InputLabel id="demo-simple-select-label">
							Location
						</InputLabel>
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={newStoreLocation}
							style={{ marginBottom: "20px" }}
							onChange={(info, value) =>
								setNewStoreLocation(value.props.value)
							}
						>
							{Object.keys(locations).map((value) => (
								<MenuItem value={value}>{value}</MenuItem>
							))}
						</Select>
					</FormControl>
				</DialogContent>
				<DialogActions>
					<Button
						color="secondary"
						variant="outlined"
						onClick={() => {
							onClose();
						}}
					>
						Cancel
					</Button>
					<Button
						color="primary"
						variant="contained"
						onClick={() => {
							createStore();
							onClose();
						}}
					>
						Create
					</Button>
				</DialogActions>
			</Dialog>
			<Snackbar
				open={snackbarOpen}
				autoHideDuration={4000}
				onClose={() => setSnackbarOpen(false)}
			>
				<Alert
					severity={severity}
					onClose={() => setSnackbarOpen(false)}
				>
					{error}
				</Alert>
			</Snackbar>
		</div>
	);
}

// This is what appears when you select a team from a class
export function TeamDetails(props) {
	const classes = useStyles();
	const { user, setUser } = React.useContext(AppContext);
	const { classData, dataUpdated } = props;
	const [teamDataLoaded, setTeamDataLoaded] = React.useState(false);
	const history = useHistory();
	const { path, url } = useRouteMatch();
	const [teamData, setTeamData] = React.useState({});
	const [createStoreDialougeOpen, setCreateStoreDialougeOpen] =
		React.useState(false);

	const className = classData.class_details.Name;

	const { teamNumber } = useParams();

	function teamDataUpdated() {
		NProgress.start();
		callAPI(
			"GetTeamInfo",
			user.token,
			{ class: classData.class_details.Name, team: teamNumber },
			function (err, res, xhr) {
				NProgress.done();
				if (!err) {
					setTeamData(res);
					setTeamDataLoaded(true);
				}
			}
		);
	}
	if (teamData.team !== undefined) {
		if (parseInt(teamNumber) !== parseInt(teamData.team)) {
			console.log({
				class: classData.class_details.Name,
				team: teamNumber,
			});
			teamDataUpdated();
		}
	}


	React.useEffect(() => {
		if (teamNumber) {
		console.log({ class: classData.class_details.Name, team: teamNumber });
		NProgress.start();
		callAPI(
			"GetTeamInfo",
			user.token,
			{ class: classData.class_details.Name, team: teamNumber },
			function (err, res, xhr) {
				NProgress.done();
				if (!err) {
					setTeamData(res);
					setTeamDataLoaded(true);
				}
			}
		);
		}
	}, [teamNumber]);

	if (!teamNumber) {
		return <Redirect to={"/" + className} />;
	}

	var peopleInTeams = [];
	for (const teamNumber of Object.keys(classData.teams)) {
		const teamMembers = JSON.parse(classData.teams[teamNumber].Members);
		peopleInTeams.push(...teamMembers);
	}

	const storeCols = [
		{ field: "number", headerName: "Store #", flex: 5 },
		{ field: "location", headerName: "Location", flex: 6 },
		{
			field: "netProfit",
			headerName: "Net Profit",
			flex: 6,
			renderCell: (params) =>
				params.value === "N/A"
					? "N/A"
					: formatNumber(params.value, true),
		},
		{
			field: "bank",
			headerName: "Bank Balance",
			flex: 7,
			renderCell: (params) => formatNumber(params.value, true),
		},
		{
			field: "remainingLoan",
			headerName: "Remaining Loan",
			flex: 8,
			renderCell: (params) => formatNumber(params.value, true),
		},
		{
			field: "salesModifier",
			headerName: "Sales Modifier",
			flex: 7,
			renderCell: (params) => (
				<NumberSelector
					onChange={(value) => {
						console.log("changing modifier to", value);
						NProgress.start();
						callAPI(
							"UpdateModifier",
							user.token,
							{
								class: className,
								store: params.row.number,
								sales: value,
								bank: null,
							},
							function (err, res, xhr) {
								NProgress.done();
								if (!err) {
									console.log("yay");
								}
							}
						);
					}}
					defaultValue={params.value}
					increment={0.05}
				/>
			),
		},
		{
			field: "bankModifier",
			headerName: "Bank Modifier",
			flex: 7,
			renderCell: (params) => (
				<NumberSelector
					onChange={(value) => {
						NProgress.start();
						callAPI(
							"UpdateModifier",
							user.token,
							{
								class: className,
								store: params.row.number,
								sales: null,
								bank: value,
							},
							function (err, res, xhr) {
								NProgress.done();
								if (!err) {
									console.log("yay");
								}
							}
						);
					}}
					defaultValue={params.value}
					increment={5000}
				/>
			),
		},
		{
			field: "view",
			headerName: " ",
			flex: 4,
			renderCell: (params) => (
				<Button
					color="primary"
					variant="contained"
					onClick={() => history.push(url + "/" + params.row.number)}
				>
					View
				</Button>
			),
		},
	];

	var storeRows = [];

	if (teamDataLoaded) {
		storeRows = Object.keys(teamData.stores).map((key, i) => {
			const store = teamData.stores[key];
			const lastMonth = Math.max(...Object.keys(store.outcomes));
			var modifier = store.modifiers[lastMonth + 1];
			if (modifier === undefined) {
				modifier = { Sales: 1, Bank: 0 };
			}

			return {
				id: i,
				number: key,
				location: store.location.Name,
				netProfit:
					lastMonth === Math.min(...Object.keys(store.outcomes))
						? "N/A"
						: store.breakdowns[lastMonth].netProfit,
				bank: store.outcomes[lastMonth].Bank,
				remainingLoan: store.outcomes[lastMonth].Loan,
				salesModifier: modifier.Sales,
				bankModifier: modifier.Bank,
			};
		});
		console.log("rows 1", storeRows);
	}

	if (teamDataLoaded) {
		console.log("rows 2", storeRows);
	}

	return (
		<RouterSwitch>
			<Route exact path={path}>
				<div className={classes.classPage}>
					<Typography variant="h4">
						10CMM {className} - Team #{teamNumber}
					</Typography>
					<section>
						<Typography variant="h5">Students</Typography>
						<PersonPicker
							onChange={(e, value) => {
								NProgress.start();
								callAPI(
									"UpdateTeamMembers",
									user.token,
									{
										class: className,
										team: teamNumber,
										members: value.map(
											(student) => student.name
										),
									},
									function (err, res, xhr) {
										NProgress.done();
										if (!err) {
											dataUpdated();
										}
									}
								);
							}}
							className={classes.classSectionItem}
							label={"Team " + teamNumber + " members"}
							placeholder={"Add members to Team " + teamNumber}
							noOptionsText="No students found. Try removing some students from other teams."
							variant="outlined"
							people={JSON.parse(classData.class_details.Students)
								.filter((student) => {
									return !peopleInTeams.includes(student);
								})
								.map((person) => {
									return {
										name: person,
										imageSrc:
											"/static/images/students/" +
											person +
											".jpeg",
									};
								})}
							defualtPeople={JSON.parse(
								classData.teams[teamNumber].Members
							).map((person, i) => {
								return {
									name: person,
									imageSrc:
										"/static/images/students/" + person + ".jpeg",
								};
							})}
							teamNumber={teamNumber}
						/>
						<Button
							style={{ marginBottom: "20px" }}
							variant="contained"
							color="primary"
							disabled={
								classData.class_details.Current_Month === 0
							}
							onClick={() => setCreateStoreDialougeOpen(true)}
						>
							New store
						</Button>
						<NewStore
							teamDataUpdated={teamDataUpdated}
							onClose={() => setCreateStoreDialougeOpen(false)}
							teamNumber={teamNumber}
							open={createStoreDialougeOpen}
							className={className}
							locations={classData.locations}
						/>

						<Paper elevation={3}>
							<div style={{ height: "300px", width: "100%" }}>
								<DataGrid
									rows={storeRows}
									columns={storeCols}
									pageSize={20}
									loading={!teamDataLoaded}
								/>
							</div>
						</Paper>
					</section>
				</div>
			</Route>
			<Route path={path + "/:storeNumber"}>
				{teamDataLoaded ? (
					<StoreDetails teamData={teamData} classData={classData} />
				) : (
					<LoadingText />
				)}
			</Route>
		</RouterSwitch>
	);
}

function MarketResearch(props) {
	const classes = useStyles();
	const marketResearch = props.marketResearch;
	const [tab, setTab] = React.useState(
		Math.max(...Object.keys(marketResearch)) - 1
	);
	const handleChange = (event, newValue) => {
		setTab(newValue);
	};

	const month = Object.keys(marketResearch)[tab];

	var cols;
	var rows;

	cols = [
		{ field: "product", headerName: "Product", flex: 1 },
		{
			field: "quantityDemanded",
			headerName: "Quantity Demanded",
			flex: 1,
		},
		{
			field: "quantitySupplied",
			headerName: "Quantity Supplied",
			flex: 1,
		},
		{
			field: "shortage",
			headerName: "Shortage (-) / Surplus (+)",
			flex: 1,
		},
		{ field: "sellers", headerName: "Number of sellers", flex: 1 },
	];

	rows = Object.keys(marketResearch[month]).map((key, i) => {
		return {
			id: i,
			product: key,
			quantityDemanded: marketResearch[month][key].demand,
			quantitySupplied: marketResearch[month][key].supplied,
			shortage:
				marketResearch[month][key].supplied -
				marketResearch[month][key].demand,
			sellers: marketResearch[month][key].sellers,
		};
	});

	console.log("rows", rows);

	return (
		<div
			style={{
				height: "600px",
				paddingLeft: "20px",
				paddingRight: "20px",
			}}
		>
			<Tabs value={tab} onChange={handleChange}>
				{Object.keys(marketResearch).map((monthNum) => (
					<Tab label={months[monthNum]} />
				))}
			</Tabs>
			<Paper
				style={{
					height: "90%",
					width: "98%",
					marginLeft: "1%",
					marginRight: "1%",
				}}
			>
				{/* <DataGrid rowHeight='35' rows={rows} columns={cols} pageSize={20} /> */}
				<DataGrid rows={rows} columns={cols} />
			</Paper>
		</div>
	);
}

function MonthManagement(props) {
	const classes = useStyles();
	const { user, setUser } = React.useContext(AppContext);
	const { className, dataUpdated, classData } = props;
	const [error, setError] = React.useState("");
	const [severity, setSeverity] = React.useState("");
	const [open, setOpen] = React.useState(false);
	var initialAutoAdvanceMonthEnabled = false;
	var currentTime = new Date();
	var initialAutoAdvanceDate = {
		day: currentTime.getDay() + 1,
		hour: currentTime.getHours(),
		minute: currentTime.getMinutes(),
		time: currentTime,
	};
	if (
		classData.auto_advance &&
		Object.keys(classData.auto_advance).length !== 0 &&
		classData.auto_advance.constructor === Object &&
		classData.auto_advance.Day
	) {
		if (!(classData.auto_advance.Day > 7)) {
			initialAutoAdvanceMonthEnabled = true;
		}
		var scheduledTime = new Date(
			2021,
			1,
			1,
			classData.auto_advance.Hour,
			classData.auto_advance.Minute
		);
		initialAutoAdvanceDate = {
			day: classData.auto_advance.Day,
			hour: scheduledTime.getHours(),
			minute: scheduledTime.getMinutes(),
			time: scheduledTime,
		};
	}
	const [autoAdvanceMonthEnabled, setAutoAdvanceMonthEnabled] =
		React.useState(initialAutoAdvanceMonthEnabled);
	const [autoAdvanceDate, setAutoAdvanceDate] = React.useState(
		initialAutoAdvanceDate
	);

	return (
		<div className={classes.classSection}>
			<Paper
				elevation={3}
				className={clsx(
					classes.classSectionItem,
					classes.clasSectionPaper
				)}
			>
				<Typography variant="h6">Month advancement</Typography>
				{parseInt(classData.class_details.Current_Month) == 0 ? (
					<div style={{ width: "60%" }}>
						<Button
							variant="contained"
							color="secondary"
							onClick={() => {
								NProgress.start();
								callAPI(
									"InitializeClass",
									user.token,
									{ class: className },
									function (err, res, xhr) {
										NProgress.done();
										if (!err) {
											dataUpdated();
											setError("Simulation started!");
											setSeverity("success");
											setOpen(true);
										} else {
											setError(res.reason);
											setSeverity("error");
											setOpen(true);
										}
									}
								);
							}}
						>
							Begin simulation
						</Button>
					</div>
				) : (
					<div>
						<Button
							variant="contained"
							color="secondary"
							onClick={() => {
								NProgress.start();
								callAPI(
									"RevertMonth",
									user.token,
									{ class: className },
									function (err, res, xhr) {
										NProgress.done();
										if (!err) {
											dataUpdated();
											setError("Month reverted");
											setSeverity("success");
											setOpen(true);
										} else {
											setError(res.reason);
											setSeverity("error");
											setOpen(true);
										}
									}
								);
							}}
							style={{ margin: "10px", marginLeft: "0px" }}
							startIcon={<ArrowBackIcon />}
						>
							Revert
						</Button>
						<Button
							variant="contained"
							color="secondary"
							style={{ margin: "10px" }}
							onClick={() => {
								NProgress.start();
								callAPI(
									"AdvanceMonth",
									user.token,
									{ class: className },
									function (err, res, xhr) {
										NProgress.done();
										if (!err) {
											dataUpdated();
											setError("Month advanced!");
											setSeverity("success");
											setOpen(true);
										} else {
											setError(res.reason);
											setSeverity("error");
											setOpen(true);
										}
									}
								);
							}}
							endIcon={<ArrowForwardIcon />}
						>
							Advance
						</Button>
					</div>
				)}

				{/* <FormControlLabel
					control={
						<Switch
							checked={autoAdvanceMonthEnabled}
							onChange={() => {
								setAutoAdvanceMonthEnabled(
									!autoAdvanceMonthEnabled
								);
							}}
							name="AutoAdvanceMonth"
						/>
					}
					label="Automagically advance the month every week"
				/>
				<Grid
					container
					direction="row"
					justify="flex-start"
					alignItems="center"
				>
					<FormControl className={classes.classSettingInput}>
						<InputLabel id="demo-simple-select-helper-label">
							Auto-Advance day
						</InputLabel>
						<Select
							disabled={!autoAdvanceMonthEnabled}
							labelId="demo-simple-select-helper-label"
							id="demo-simple-select-helper"
							value={autoAdvanceDate.day}
							onChange={(e) => {
								setAutoAdvanceDate({
									...autoAdvanceDate,
									day: e.target.value,
								});
							}}
						>
							<MenuItem value={1}>Monday</MenuItem>
							<MenuItem value={2}>Tuesday</MenuItem>
							<MenuItem value={3}>Wednesday</MenuItem>
							<MenuItem value={4}>Thursday</MenuItem>
							<MenuItem value={5}>Friday</MenuItem>
							<MenuItem value={6}>Saturday</MenuItem>
							<MenuItem value={7}>Sunday</MenuItem>
						</Select>
					</FormControl>
					<FormControl className={classes.classSettingInput}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<TimePicker
								className={classes.classSettingInput}
								disabled={!autoAdvanceMonthEnabled}
								// variant="inline"
								margin="normal"
								id="time-picker"
								label="Auto-Advance time"
								value={autoAdvanceDate.time}
								onChange={(time) => {
									if (time) {
										setAutoAdvanceDate({
											...autoAdvanceDate,
											time: time,
											hour: time.getHours(),
											minute: time.getMinutes(),
										});
									}
									console.log(autoAdvanceDate);
								}}
							/>
						</MuiPickersUtilsProvider>
					</FormControl>
				</Grid>
				<Button
					variant="contained"
					color="secondary"
					onClick={() => {
						NProgress.start();
						let day = autoAdvanceDate.day;
						if (!autoAdvanceMonthEnabled) {
							day = 69;
						}
						callAPI(
							"SetAutoAdvance",
							user.token,
							{
								class: className,
								day: day,
								hour: autoAdvanceDate.hour,
								minute: autoAdvanceDate.minute,
							},
							function (err, res, xhr) {
								NProgress.done();
								if (!err) {
									console.log(res);
								}
							}
						);
					}}
				>
					Set auto advance
				</Button> */}
			</Paper>
			<Snackbar
				open={open}
				autoHideDuration={2000}
				onClose={() => setOpen(false)}
			>
				<Alert severity={severity} onClose={() => setOpen(false)}>
					{error}
				</Alert>
			</Snackbar>
		</div>
	);
}

const useLocationViewerStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		height: "100%",

		paddingTop: theme.spacing(2),
		paddingLeft: theme.spacing(5),
		paddingRight: theme.spacing(5),
		paddingBottom: theme.spacing(3),
	},
	top: { display: "flex" },
	titleText: { marginRight: theme.spacing(3) },
	header: {
		marginBottom: theme.spacing(2),
	},
	spacingBelow: { marginBottom: theme.spacing(2) },
}));

const dsiableTheOnesBenIsAngryAbout = true;
function LocationViewer({ classData }) {
	const classes = useLocationViewerStyles();

	console.warn("CLASSSES DATA ==============");
	console.log(classData);
	console.warn("CLASSSES DATA ==============");

	// State
	const { user, setUser } = React.useContext(AppContext);
	const [createTeamDialougeOpen, setCreateTeamDialougeOpen] =
		React.useState(false);
	const [locations, setLocations] = React.useState(
		classData.locations
			? Object.values(classData.locations).length === 0
				? Object.values(initialLocations)
				: Object.values(classData.locations)
			: Object.values(initialLocations)
	);
	const [dataUpdated, setDataUpdated] = React.useState(false);
	const [errorMessage, setErrorMessage] = React.useState("An error occured");
	const [errorOpen, setErrorOpen] = React.useState(false);

	console.log("Locations", locations);

	const month0Or1 =
		classData.class_details.Current_Month == 0 ||
		classData.class_details.Current_Month == 1;

	// Other data
	const locationColumns = [
		{
			field: "Name",
			headerName: "Location",
			flex: 2,
		},
		{
			field: "Rent",
			headerName: "Rent",
			flex: 1.5,
			renderCell: (params) =>
				month0Or1 ? (
					<TextField
						// label={`Rent`}
						placeholder="Rent"
						variant="outlined"
						value={params.value}
						type="number"
						onChange={(e) => {
							let newLocs = [...locations];
							newLocs[params.id].Rent = e.target.value;
							newLocs[params.id].Bond = Math.round(3 * e.target.value);
							setLocations(newLocs);
							setDataUpdated(true);
						}}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									$
								</InputAdornment>
							),
						}}
					/>
				) : (
					formatNumber(params.value, true)
				),
		},
		{
			field: "Sales_Modifier",
			headerName: "Sales Modifier",
			flex: 2,
			renderCell: (params) =>
				month0Or1 ? (
					<TextField
						disabled={dsiableTheOnesBenIsAngryAbout}
						placeholder="Sales Mod"
						variant="outlined"
						value={params.value}
						type="number"
						onChange={(e) => {
							let newLocs = [...locations];
							newLocs[params.id].Sales_Modifier = e.target.value;
							setLocations(newLocs);
							setDataUpdated(true);
						}}
						InputProps={{
							startAdornment: (
								<InputAdornment position="end">
									x
								</InputAdornment>
							),
						}}
					/>
				) : (
					`${Math.round(params.value * 100 * 10) / 10}%`
				),
		},
		{
			field: "Storage_Capacity",
			headerName: "Storage Capacity",
			flex: 2,
			renderCell: (params) =>
				month0Or1 ? (
					<TextField
						disabled={dsiableTheOnesBenIsAngryAbout}
						placeholder="Storage Capacity"
						variant="outlined"
						value={params.value}
						type="number"
						onChange={(e) => {
							let newLocs = [...locations];
							newLocs[params.id].Storage_Capacity =
								e.target.value;
							setLocations(newLocs);
							setDataUpdated(true);
						}}
					/>
				) : (
					params.value
				),
		},
		{
			field: "Staff_Capacity",
			headerName: "Staff Capacity",
			flex: 2,
			renderCell: (params) =>
				month0Or1 ? (
					<TextField
						disabled={dsiableTheOnesBenIsAngryAbout}
						placeholder="Staff Cap"
						variant="outlined"
						value={params.value}
						type="number"
						onChange={(e) => {
							let newLocs = [...locations];
							newLocs[params.id].Staff_Capacity = e.target.value;
							setLocations(newLocs);
							setDataUpdated(true);
						}}
					/>
				) : (
					params.value
				),
		},

		{
			field: "Bond",
			headerName: "Bond",
			flex: 1.5,
			renderCell: (params) =>
				month0Or1 ? (
					<TextField
						disabled={dsiableTheOnesBenIsAngryAbout}
						placeholder="Bond"
						variant="outlined"
						value={params.value}
						type="number"
						onChange={(e) => {
							let newLocs = [...locations];
							newLocs[params.id].Bond = e.target.value;
							setLocations(newLocs);
							setDataUpdated(true);
						}}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									$
								</InputAdornment>
							),
						}}
					/>
				) : (
					formatNumber(params.value, true)
				),
		},
		{
			field: "Cost_of_Fittings",
			headerName: "Fittings Cost",
			flex: 2,
			renderCell: (params) =>
				month0Or1 ? (
					<TextField
						disabled={dsiableTheOnesBenIsAngryAbout}
						placeholder="Fittings Cost"
						variant="outlined"
						value={params.value}
						type="number"
						onChange={(e) => {
							let newLocs = [...locations];
							newLocs[params.id].Cost_of_Fittings =
								e.target.value;
							setLocations(newLocs);
							setDataUpdated(true);
						}}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									$
								</InputAdornment>
							),
						}}
					/>
				) : (
					formatNumber(params.value, true)
				),
		},
	];

	const locationRows = locations.map((row, i) => ({ ...row, id: i }));

	return (
		<div className={classes.root}>
			<div className={classes.top}>
				<Typography
					variant="h5"
					className={`${classes.spacingBelow} ${classes.titleText}`}
				>
					Locations
				</Typography>
				<Box marginLeft="auto">
					<Button
						disabled={!dataUpdated}
						variant="contained"
						color="secondary"
						onClick={() => {
							let locationsObject = {};
							for (let i = 0; i < locations.length; i++) {
								if (locations[i] !== undefined) {
									locationsObject[locations[i].Name] =
										locations[i];
								}
							}
							NProgress.start();
							callAPI(
								"SetLocationCosts",
								user.token,
								{
									class: classData.class_details.Name,
									costs: locationsObject,
								},
								function (err, res, xhr) {
									NProgress.done();
									if (err) {
										setErrorOpen(true);
									}
								}
							);
							setDataUpdated(false);
						}}
					>
						Save changes
					</Button>
				</Box>
			</div>

			<Paper
				style={{
					height: "calc(90vh - 150px)",
					width: "100%",
				}}
			>
				<DataGrid rows={locationRows} columns={locationColumns} />
			</Paper>

			<Dialog
				open={createTeamDialougeOpen}
				onClose={() => {
					setCreateTeamDialougeOpen(false);
				}}
			>
				<DialogTitle>Create a new location</DialogTitle>
				<DialogContent></DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							setCreateTeamDialougeOpen(false);
						}}
						color="primary"
						variant="outlined"
					>
						Cancel
					</Button>
					<Button
						onClick={() => {
							setCreateTeamDialougeOpen(false);
						}}
						color="secondary"
						variant="contained"
						autoFocus
					>
						Create
					</Button>
				</DialogActions>
			</Dialog>

			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
				open={errorOpen}
				onClose={() => {
					setErrorOpen(false);
				}}
			>
				<MuiAlert severity="error" elevation={6} variant="filled">
					{errorMessage}
				</MuiAlert>
			</Snackbar>
		</div>
	);
}

// This is what appears when you select a class from the classes tab
export function ClassDetails(props) {
	const { classesData, dataUpdated } = props;
	const classes = useStyles();
	const { user, setUser } = React.useContext(AppContext);
	const { path, url } = useRouteMatch();
	const [createTeamDialougeOpen, setCreateTeamDialougeOpen] =
		React.useState(false);

	const [tab, setTab] = React.useState(0);
	const [newTeamStudents, setNewTeamStudents] = React.useState([]);
	const [autoAdvanceMonthEnabled, setAutoAdvanceMonthEnabled] =
		React.useState(false);
	const [autoAdvanceDate, setAutoAdvanceDate] = React.useState({
		day: 1,
		hour: 1,
		minute: 1,
		time: new Date(),
	});
	const history = useHistory();

	const { className } = useParams();
	if (!className) {
		return <Redirect to={"/"} />;
	}

	const classData = classesData[className];

	let peopleInTeams = [];
	for (const teamNumber of Object.keys(classData.teams)) {
		const teamMembers = JSON.parse(classData.teams[teamNumber].Members);
		peopleInTeams.push(...teamMembers);
	}

	const teamCols = [
		{ field: "number", headerName: "Team #", flex: 2 },
		{ field: "completed", headerName: "Submitted descions", flex: 3 },
		{
			field: "netProfit",
			headerName: "Net Profit",
			flex: 3,
			renderCell: (params) => formatNumber(params.value, true),
		},
		{
			field: "bank",
			headerName: "Bank Balance",
			flex: 3,
			renderCell: (params) => formatNumber(params.value, true),
		},
		{
			field: "remainingLoan",
			headerName: "Remaining Loan",
			flex: 3,
			renderCell: (params) => formatNumber(params.value, true),
		},
		{
			field: "members",
			headerName: "Members",
			flex: 3,
			renderCell: (params) => (
				<AvatarGroup max={5}>
					{params.value.map((member, i) => {
						return (
							<Tooltip
								key={encodeURIComponent(member)}
								title={member}
							>
								<Avatar
									alt={member}
									src={"/static/images/students/" + member + ".jpeg"}
									className={classes.smallAvatar}
								/>
							</Tooltip>
						);
					})}
				</AvatarGroup>
			),
		},
	];

	const teamRows = Object.keys(classData.teams).map((key, i) => {
		const team = classData.teams[key];
		return {
			id: i,
			number: team.Team,
			completed: classData.completed_teams.includes(team.Team)
				? "Yes"
				: "No",
			netProfit: team.NetProfit,
			bank: team.Bank,
			remainingLoan: team.RemainingLoan,
			members: JSON.parse(team.Members),
		};
	});
	console.log(teamRows);
	function createTeam() {
		NProgress.start();
		callAPI(
			"AddTeam",
			user.token,
			{
				class: className,
				members: newTeamStudents.map((student) => student.name),
			},
			function (err, res, xhr) {
				NProgress.done();
				if (!err) {
					dataUpdated();
				}
			}
		);
	}

	function handletableclick(data, props) {
		props.history.push("/pathToRoute=" + data);
	}

	const handleChange = (event, newValue) => {
		setTab(newValue);
	};

	return (
		<RouterSwitch>
			<Route exact path={path}>
				<Tabs
					value={tab}
					onChange={handleChange}
					textColor="secondary"
					centered
				>
					<Tab label="Overview" />
					<Tab label="Teams" />
					<Tab label="Locations" />
					{classData.class_details.Current_Month != 0 &&
						classData.class_details.Current_Month != 1 && (
							<Tab label="Market Research" />
						)}
				</Tabs>
				<TabPanel value={tab} index={0} className={classes.tabPage}>
					<div className={classes.storePage}>
						<Typography variant="h3">10CMM {className}</Typography>
						<div className={classes.classSection}>
							<FigureRow>
								<Figure
									big
									title="Current month"
									value={
										months[
										classData.class_details
											.Current_Month
										]
									}
								/>
								<Figure
									big
									title="Teams submitted"
									value={classData.statistics.num_completed}
								/>
								<Figure
									big
									title="Class net profit last month"
									value={formatNumber(
										classData.statistics.class_net_profit,
										true
									)}
								/>
							</FigureRow>
						</div>

						{/*
						<SimpleTable
							className={classes.classSectionItem}
							cols={teamCols}
							rows={teamRows}
							link={true}
							linkTargets={teamRows.map((team, i) => {
								return url + "/" + team.number;
							})}
							captionText="Select a team to view detailed information about it"
						/>*/}

						<MonthManagement
							className={className}
							classData={classData}
							dataUpdated={dataUpdated}
						/>
					</div>
				</TabPanel>
				<TabPanel value={tab} index={1} className={classes.tabPage}>
					<div className={classes.storePage}>
						<Typography variant="h5">Teams</Typography>
						<Button
							className={classes.classSectionItem}
							color="secondary"
							variant="contained"
							onClick={() => {
								setCreateTeamDialougeOpen(true);
							}}
						>
							Create team
						</Button>
						<Paper elevation={3}>
							<div style={{ height: 400, width: "100%" }}>
								<DataGrid
									rows={teamRows}
									columns={teamCols}
									pageSize={20}
									onRowClick={(params) =>
										history.push(
											url + "/" + params.row.number
										)
									}
								/>
							</div>
						</Paper>
					</div>
				</TabPanel>
				<TabPanel value={tab} index={2} className={classes.tabPage}>
					<LocationViewer classData={classData} />
				</TabPanel>
				<TabPanel value={tab} index={3} style={{ height: "100%" }}>
					<MarketResearch
						marketResearch={classData.market_research}
					/>
				</TabPanel>
				<Dialog
					open={createTeamDialougeOpen}
					onClose={() => {
						setCreateTeamDialougeOpen(false);
					}}
				>
					<DialogTitle>Create a new team for {className}</DialogTitle>
					<DialogContent>
						<DialogContentText>
							If you simply want to add or remove members from an
							existing team, select it from the list of teams on
							this page. If you want to create a new team, select
							the students who will be in it below.
						</DialogContentText>
						<PersonPicker
							fullWidth
							autoFocus
							people={JSON.parse(classData.class_details.Students)
								.filter((student) => {
									return !peopleInTeams.includes(student);
								})
								.map((person) => {
									return {
										name: person,
										imageSrc:
											"/static/images/students/" +
											person +
											".jpeg",
									};
								})}
							label="Students to be in this team"
							noOptionsText="No students found. Try removing some students from other teams."
							variant="outlined"
							onChange={(e, value) => {
								setNewTeamStudents(value);
							}}
						/>
					</DialogContent>
					<DialogActions>
						<Button
							color="secondary"
							variant="outlined"
							onClick={() => {
								setCreateTeamDialougeOpen(false);
							}}
						>
							Cancel
						</Button>
						<Button
							color="primary"
							variant="contained"
							onClick={() => {
								setCreateTeamDialougeOpen(false);
								createTeam();
							}}
						>
							Create
						</Button>
					</DialogActions>
				</Dialog>
			</Route>
			<Route path={path + "/:teamNumber"}>
				<TeamDetails classData={classData} dataUpdated={dataUpdated} />
			</Route>
		</RouterSwitch>
	);
}

// This is what appears on the classes tab
export default function Classes(props) {
	const classes = useStyles();
	const { user, setUser } = React.useContext(AppContext);
	const { path, url } = useRouteMatch();
	const { classData, dataUpdated, classDataLoaded } = props;

	const cols = [
		{ id: "name", label: "Class name" },
		{ id: "month", label: "Current month" },
		{ id: "completed", label: "Teams submitted" },
		{ id: "students", label: "Students" },
	];

	const parsedClassRows = Object.values(classData).map((row, i) => {
		return {
			name: (
				<Tooltip title={"10CMM " + row.class_details.Name}>
					<span>{row.class_details.Name}</span>
				</Tooltip>
			),
			month: (
				<Tooltip
					title={"Month " + months[row.class_details.Current_Month]}
				>
					<span>{months[row.class_details.Current_Month]}</span>
				</Tooltip>
			),
			completed: (
				<Tooltip
					title={
						row.statistics.num_completed +
						" teams have submitted decisions"
					}
				>
					<span>
						{row.statistics.num_completed +
							"/" +
							(row.statistics.num_completed +
								row.statistics.num_incompleted)}
					</span>
				</Tooltip>
			),
			students: (
				<AvatarGroup max={4}>
					{JSON.parse(row.class_details.Students).map(
						(student, i) => {
							return (
								<Tooltip
									key={encodeURIComponent(student)}
									title={student}
								>
									<Avatar
										alt={student}
										src={
											"/static/images/students/" +
											student +
											".jpeg"
										}
										className={classes.smallAvatar}
									/>
								</Tooltip>
							);
						}
					)}
				</AvatarGroup>
			),
		};
	});

	return (
		<RouterSwitch>
			<Route exact path={path}>
				<div className={classes.classPage}>
					{classDataLoaded ? (
						<SimpleTable
							cols={cols}
							rows={parsedClassRows}
							link={true}
							linkTargets={Object.values(classData).map(
								(row, i) => {
									return path + row.class_details.Name;
								}
							)}
							captionText="Select a class to view detailed information about it."
							className={classes.classSection}
						/>
					) : (
						<LoadingText />
					)}
				</div>
			</Route>
			<Route path={path + ":className"}>
				{classDataLoaded ? (
					<ClassDetails
						classesData={classData}
						dataUpdated={dataUpdated}
					/>
				) : (
					<LoadingText />
				)}
			</Route>
		</RouterSwitch>
	);
}
