
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { ThemeProvider, createTheme, makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import React from "react";
import AppContext from "../context";
import { Box, Button, Fade, TextField } from "@material-ui/core";

const CLIENT_ID =
	"450837736841-32h26l1u1lgu50ka39gig2etiebrl0h6.apps.googleusercontent.com";

const useStyles = makeStyles((theme) => ({
	mainContent: {
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		paddingTop: "100px",
		color: theme.palette.text.primary
	},
	toolbarRight: {
		display: "flex",
		alignItems: "center",
		marginLeft: "auto"
	},
	dollarIcon: {
		height: "90%",
		width: "90%"
	},
	WCommerce: {
		color: "#FCC000"
	}
}));

function queryParam(param) {
	var field = param;
	var url = window.location.href;
	if (url.indexOf("?" + field + "=") != -1) {
		return true;
	} else if (url.indexOf("&" + field + "=") != -1) {
		return true;
	}
	return false;
}

export default function Login(props) {
	const { user, setUser } = React.useContext(AppContext);
	const [showEmailSignIn, setShowEmailSignIn] = React.useState(false);

	React.useEffect(() => {
		setTimeout(() => {
			setShowEmailSignIn(true);
		}, 3000);
	}, []);

	const classes = useStyles();

	document.title = "Please log in - Retail Simulation";

	async function onGoogleSignIn(gResponse) {
		console.log("G RESPONSE", gResponse)
		// NProgress.start();
		const response = await fetch(`/TokenSignIn`, { method: "POST", body: JSON.stringify({ token: gResponse.credential }) })
		// NProgress.done();
		if (response.status === 200) {
			const newUser = await response.json()
			setUser(newUser)
			window.makeAlert("Login succeeded", "success")
		} else {
			window.makeAlert("Login error (1)", "error")
		}
	}

	window.onGoogleSignIn = onGoogleSignIn

	return (
		<React.Fragment>
			<AppBar position="fixed">
				<Toolbar>
					<Typography variant="h6" style={{flexGrow: 1}}>Retail Simulation</Typography>
					<Fade in={showEmailSignIn} timeout={1000}>
							<Box style={{ display: "flex", alignItems: "center" }}>
						<ThemeProvider theme={createTheme({
							palette: {
								type: "dark",
								primary: {
									main: "#222222",
								},
								secondary: {
									main: "#FCC000",
								},
							},
						})}>
							<TextField
								label="Enter email"
								variant="outlined"
								color="secondary"
								style={{ margin: 0 }}
								size="small"
								onKeyDown={(ev) => {
									console.log(`Pressed keyCode ${ev.key}`);
									if (ev.key === 'Enter') {
										// Do code here
										ev.preventDefault();

										fetch('/EmailSignIn/' + ev.target.value, {method: "POST"}).then(async function(response) {
											if (!response.ok) {
												// make the promise be rejected if we didn't get a 2xx response
												try {
												const json = await response.json()
												window.makeAlert(json.message, "error")
												} catch (e) {
													window.makeAlert("Couldn't log you in", "error")
												}
											} else {
												const json = await response.json()

												if (json.result === "Success")
												window.makeAlert("Check your emails for a link to sign in!", "success")
											else 
												window.makeAlert(json.reason ||"Couldn't log you in", "error")
											}
										}).catch(function(err) {
											window.makeAlert("Couldn't reach our servers. Are you connected to the internet?", "error")
										});
									}
								}}
							// InputLabelProps={{ style: { color: "white" } }}
							/>
							<Typography variant="body1" style={{ color: "white", paddingLeft: 14, paddingRight: 16}}>or</Typography>
						</ThemeProvider>
						</Box>
					</Fade>
					<div className={classes.toolbarRight}>
						<div id="g_id_onload"
							data-client_id={CLIENT_ID}
							data-ux_mode="popup"
							data-callback="onGoogleSignIn"
							data-auto_select="true"
							data-close_on_tap_outside="false">
						</div>

						<div className="g_id_signin"
							data-type="standard"
							data-shape="rectangular"
							data-theme="outline"
							data-text="signin_with"
							data-size="large"
							data-logo_alignment="left">
						</div>
					</div>
				</Toolbar>
			</AppBar>
			<Container className={classes.mainContent}>
				<Grid container spacing={10}>
					<Grid container item xs={12} spacing={3}>
						<Grid item xs={6}>
							<Typography variant="h2">Welcome to</Typography>
							<Typography
								variant="h2"
								className={classes.WCommerce}
							>
								Retail Simulation
							</Typography>
							<Typography variant="h4">
								Launch a clothing business in the virtual world.
								Learn how to run a business and put your
								entrepreneur skills to the test.
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<img
								src="/static/images/diagram2.svg"
								className={classes.dollarIcon}
							></img>
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</React.Fragment>
	);
}
