import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Fade } from "@material-ui/core";

const products = [
	["Bargain Clothing", "Mens Casual", "50", "10", "$20"],
	["", "Mens Formal", "20", "20", "$40"],
	["", "Womens Casual", "50", "10", "$20"],
	["", "Womens Formal", "20", "20", "$40"],
	["", "Boys", "50", "5", "$10"],
	["", "Girls", "50", "5", "$10"],
	["Designer Clothing", "Mens Casual", "25", "40", "$84"],
	["", "Mens Formal", "10", "80", "$168"],
	["", "Womens Casual", "25", "40", "$84"],
	["", "Womens Formal", "10", "80", "$168"],
	["", "Boys", "25", "25", "$52.50"],
	["", "Girls", "25", "25", "$52.50"],
	["Outdoor Clothing", "Mens", "20", "50", "$120"],
	["", "Womans", "20", "50", "$120"],
	["", "Kids", "20", "25", "$60"],
];

const locations = [
	["Newtown", "Little shop", "2000", "S6,000", "Average", "1000", "25000", "2"],
	["", "Medium shop", "4000", "12000", "Average", "2000", "30000", "4"],
	["", "Large shop", "6000", "18000", "Average", "4000", "35000", "5"],
	["Lampton Quay", "Very little shop", "2000", "6000", "High", "600", "20000", "1"],
	["", "Little shop", "4000", "12000", "High", "1000", "25000", "2"],
	["", "Medium shop", "8000", "24000", "High", "2000", "30000", "4"],
	["Queensgate", "Little shop", "3000", "9000", "Above average", "1000", "25000", "2"],
	["", "Medium shop", "6000", "18000", "Above average", "2000", "30000", "4"],
	["", "Large shop", "9000", "27000", "Above average", "4000", "35000", "5"],
	["Cuba", "Very little shop", "1500", "4500", "Above average", "600", "20000", "1"],
	["", "Little shop", "3000", "9000", "Above average", "1000", "25000", "2"],
	["Airport", "Medium shop", "6000", "18000", "Above average", "2000", "30000", "4"],
	["Industrial", "Large shop", "5000", "15000", "Low", "4000", "35000", "5"],
	["", "Large shop", "5000", "15000", "Low", "4000", "35000", "5"],
	["", "Very Large shop", "8000", "24000", "Low", "8000", "40000", "7"],
];

const staffing = [
	["0", "0", "0", "0"],
	["1", "4725", "5287.5", "1.5"],
	["2", "9450", "10575", "3"],
	["3", "14175", "15862.5", "4.5"],
	["4", "18900", "21150", "6"],
	["5", "23625", "26437.5", "7.5"],
	["6", "28350", "31725", "9"],
	["7", "33075", "37012.5", "10.5"],
];

const useStyles = makeStyles((theme) => ({

	classPage: {
		marginTop: theme.spacing(6),
		marginInline: theme.spacing(6),
		color: theme.palette.type === "dark" ? "white" : "black",
	},
	classSection: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
}));

export const handbook = {
	["Retail Clothing Business"]: {
		Overview: (
			<div>
				<Typography variant="body1">
					Your two-term course in Year 10 Commerce is going to revolve around a simulated retail clothing
					business.
				</Typography>
				<br />
				<Typography variant="body1">You will be:</Typography>
				<ul>
					<li>
						<Typography variant="body1">Setting goals</Typography>
					</li>
					<li>
						<Typography variant="body1">Using financial information for decision making</Typography>
					</li>
					<li>
						<Typography variant="body1">Marketing a product to a target market</Typography>
					</li>
					<li>
						<Typography variant="body1">Managing staff, stock, and cash flow</Typography>
					</li>
					<li>
						<Typography variant="body1">Reflecting on progress and recommending improvements</Typography>
					</li>
				</ul>
				<br />

				<Typography variant="body1">
					The aim of the game is to sell your business for the highest value at the end of the two terms. You
					will also receive a score based on how ethical your business was.
				</Typography>
				<br />

				<Typography variant="body1">
					Through this simulation you will also explore some of the key business processes and concepts.
				</Typography>
			</div>
		),
		["Start up"]: (
			<div>
				<Typography variant="body1">
					Before you will be able to start operating your business, you will need to do the following:
				</Typography>
				<ul>
					<li>
						<Typography variant="body1">Organise yourself into groups of 1 to 3 people</Typography>
					</li>
					<li>
						<Typography variant="body1">Analyse the market</Typography>
					</li>
					<li>
						<Typography variant="body1">
							Identify a market opportunity and create a business plan
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							Determine financial requirements to start up and run for 6 months
						</Typography>
					</li>
					<li>
						<Typography variant="body1">Apply for a loan</Typography>
					</li>
					<li>
						<Typography variant="body1">Secure a shop location</Typography>
					</li>
				</ul>
			</div>
		),
		["Running your business"]: (
			<div>
				<Typography variant="body1">Every month you may:</Typography>
				<ul>
					<li>
						<Typography variant="body1">Order stock</Typography>
					</li>
					<li>
						<Typography variant="body1">Set prices</Typography>
					</li>
					<li>
						<Typography variant="body1">Advertise</Typography>
					</li>
					<li>
						<Typography variant="body1">Allocate staffing</Typography>
					</li>
					<li>
						<Typography variant="body1">Conduct market research</Typography>
					</li>
					<li>
						<Typography variant="body1">Repay part of your loan</Typography>
					</li>
				</ul>
			</div>
		),
	},
	Markets: {
		Markets: (
			<div>
				<Typography variant="body1">
					These three graphs show the size of the markets for <b>Designer</b>, <b>Bargain</b>, and{" "}
					<b>Outdoors</b> clothing. This represents how many of each product that customers are willing and
					able to buy each month.
					<br />
					<br />
					If your business is operating in the same market as another business, you are said to be in{" "}
					<b>Competition</b>. The market will be split amongst all competitors in the market. Your business’s
					share of the market is called your <b>Market Share</b>.
					<br />
					<br />
					Note: These numbers indicate the quantity demanded for each product at RRP or Market Price. Lower or
					higher prices, as well as other market factors will increase or decrease the amount of products that
					customers are willing and able to buy.
					<br />
					<br />
					By purchasing market research each month you will be able to find the real quantity demanded for
					each product.
				</Typography>
				<div>
					<img style={{ width: "100%", marginLeft: "-10px" }} src="/static/images/handbook/bargain_market.svg" />
					<img style={{ width: "100%", marginLeft: "-10px" }} src="/static/images/handbook/designer_market.svg" />
					<img style={{ width: "100%", marginLeft: "-10px" }} src="/static/images/handbook/outdoor_market.svg" />
				</div>
			</div>
		),
		["Target Market"]: (
			<div>
				<Typography variant="body1">
					When marketing a product, it is important to know who your <b>Target Market</b> is. These are the
					people who are most likely to buy your product. The following graphs break up the market for each
					product into age, gender, and income.
				</Typography>
				<div>
					<img
						style={{ width: "100%", marginLeft: "-10px" }}
						src="/static/images/handbook/high_income_males_demand.jpg"
					/>
					<img
						style={{ width: "100%", marginLeft: "-10px" }}
						src="/static/images/handbook/high_income_females_demand.jpg"
					/>
					<img
						style={{ width: "100%", marginLeft: "-10px" }}
						src="/static/images/handbook/low_income_males_demand.jpg"
					/>
					<img
						style={{ width: "100%", marginLeft: "-10px" }}
						src="/static/images/handbook/low_income_females_demand.jpg"
					/>
				</div>
			</div>
		),
	},
	Marketing: {
		["Product - what you’re selling"]: (
			<div>
				<Typography variant="body1">
					Your business will sell only a particular set of products. You should pick an area to focus on, such
					as ‘Men’s Bargain Clothing’, or ‘Casual Designer Clothing’ for example. Cater to a particular target
					market.
					<br />
					<br />
					You can choose to order stock from different niches each month, but you can only stock up to four
					different niches on your shelves in a single month. You will either need to drop the price to sell
					the remaining stock or else discard it.
					<br />
					<br />
					You will be competing with other businesses in the class, so try to find a niche that other
					businesses are not selling in.
				</Typography>
			</div>
		),
		Products: (
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell></TableCell>
						<TableCell align="left">Product niche</TableCell>
						<TableCell align="right">Minimum order</TableCell>
						<TableCell align="right">Cost per item</TableCell>
						<TableCell align="right">RRP</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{products.map((product, i) => (
						<TableRow key={i}>
							<TableCell component="th" scope="row">
								{product[0]}
							</TableCell>
							<TableCell align="left">{product[1]}</TableCell>
							<TableCell align="right">{product[2]}</TableCell>
							<TableCell align="right">{product[3]}</TableCell>
							<TableCell align="right">{product[4]}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		),
		["Terms explained - products"]: (
			<div>
				<Typography variant="body1">
					<b>Bargain clothing</b> is produced using cheap labour and materials, commonly made in China.
					<br />
					<br />
					<b>Designer clothing</b> is higher quality than Bargain clothing. It is fashionable and may carry a
					well known label.
					<br />
					<br />
					<b>Outdoor clothing</b> includes woollen and wet-weather gear for keeping warm and dry in adverse
					conditions.
					<br />
					<br />
					<b>Mens, Womens, Kids products</b> will be cut to different shapes and sizes.
					<br />
					<br />
					<b>Casual clothing</b> will include t-shirts, jeans, informal dresses, jackets, street shoes, etc.
					<br />
					<br />
					<b>Formal clothing</b> will include dress pants, button down shirts, and blazers for men, dresses
					for women.
				</Typography>
			</div>
		),
		["Price - what the customer pays to get the product"]: (
			<div>
				<Typography variant="body1">
					You can choose to sell at the recommended price, or you can sell at a lower or higher price. A
					higher price will mean you earn more gross profit for every unit sold, but will mean that less
					customers are willing to buy your product. Likewise for a low price, you will be making less gross
					profit, but more customers will want to buy your products.
					<br />
					<br />
					You can set the prices of the different products you sell independently, as well as change prices
					each month.
				</Typography>
			</div>
		),
		["Terms explained - price"]: (
			<div>
				<Typography variant="body1">
					<b>Customers</b> are also called consumers, they are the people who will be buying your products.
					<br />
					<br />
					<b>Cost</b> is what the business pays to buy the products from a supplier.
					<br />
					<br />
					<b>Price</b> is what the customer pays to buy the products from the business.
					<br />
					<br />
					<b>Gross profit</b> is equal to price minus cost for all of the products sold in the time period.
					<br />
					<br />
					<b>Price modifier</b> is the percentage modifier calculated as a percentage of the RRP price of the
					product.
					<br />
					<br />
					<b>Sales modifier</b> is how many more (or less) customers will buy your product due to the increase
					(or decrease) in price
				</Typography>
			</div>
		),
		Locations: (
			<Table>
				<TableHead>
					<TableRow>
						<TableCell></TableCell>
						<TableCell align="left">Location</TableCell>
						<TableCell align="right">Rent (monthly)</TableCell>
						<TableCell align="right">Bond</TableCell>
						<TableCell align="right">Foot traffic</TableCell>
						<TableCell align="right">Storage capacity</TableCell>
						<TableCell align="right">Cost of fittings</TableCell>
						<TableCell align="right">Staff capacity</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{locations.map((product, i) => (
						<TableRow key={i}>
							<TableCell component="th" scope="row">
								{product[0]}
							</TableCell>
							<TableCell align="left">{product[1]}</TableCell>
							<TableCell align="right">{product[2]}</TableCell>
							<TableCell align="right">{product[3]}</TableCell>
							<TableCell align="right">{product[4]}</TableCell>
							<TableCell align="right">{product[5]}</TableCell>
							<TableCell align="right">{product[6]}</TableCell>
							<TableCell align="right">{product[7]}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		),
		["Place - how the customer gets access to the product"]: (
			<div>
				<Typography variant="body1">
					You will need to lease a shop from which to operate your business.
				</Typography>
				<br />
				<Typography variant="body1">You will need to pick a location that is:</Typography>
				<ul>
					<li>
						<Typography variant="body1">Not too expensive for you</Typography>
					</li>
					<li>
						<Typography variant="body1">Accessible to your target market</Typography>
					</li>
					<li>
						<Typography variant="body1">Has sufficient storage and staff capacity</Typography>
					</li>
				</ul>
				<br />

				<Typography variant="body1">
					Beware, if another business in the class wishes to lease the same store as you, you may need to pay
					a higher price to secure your desired location. If you plan on selling low markup (bargain)
					clothing, you should aim for a large capacity
				</Typography>
			</div>
		),
		["Terms explained - place"]: (
			<div>
				<Typography variant="body1">
					<b>Rent</b> is the amount you will need to pay each month.
					<br />
					<br />
					<b>Bond</b> is a one time payment to the landlord that will be returned to you at the end of your
					lease. If you break the agreement with the landord, you may forfeit your bond.
					<br />
					<br />
					<b>Foot traffic</b> indicates how easy it is for customers to access your shop, i.e. whether you
					will get more or less customers in this location.
					<br />
					<br />
					<b>Store capacity</b> indicates the maximum number of clothing items your store can hold in a month.
					<br />
					<br />
					<b>Cost of fittings</b> indicates how much it will cost to set-up the location as a shop. This
					includes: cash registers, shelving, racks, changing rooms, and counters.
					<br />
					<br />
					<b>Staff capacity</b> indicates how many staff you can have simultaneously working in your shop.
					More staff means better customer service, and leads to more sales.
				</Typography>
			</div>
		),
		["Promotion - how the business communicates with their target market"]: (
			<div>
				<Typography variant="body1">
					Each month you can choose one of the following ways to promote your business. Each form of promotion
					will slightly increase your monthly sales. If you can justify your choice and design* of promotion
					in relation to your target market, the impact of your promotion will be increased.
					<br />
					<br />
					*Create promotional material that appeals to your target market and show your teacher to increase
					the effect of your advertising.
				</Typography>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell align="left">Promotion</TableCell>
							<TableCell align="right">Cost (month)</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell component="th" scope="row">
								None
							</TableCell>
							<TableCell align="right">$0</TableCell>
						</TableRow>
						<TableRow>
							<TableCell component="th" scope="row">
								Online
							</TableCell>
							<TableCell align="right">$1000</TableCell>
						</TableRow>
						<TableRow>
							<TableCell component="th" scope="row">
								Radio
							</TableCell>
							<TableCell align="right">$1,500</TableCell>
						</TableRow>
						<TableRow>
							<TableCell component="th" scope="row">
								Newspaper
							</TableCell>
							<TableCell align="right">$1,500</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</div>
		),
		["Market Research"]: (
			<div>
				<Typography variant="body1">
					Each month you can choose to carry out market research. For $500 you can carry out simple market
					research, which will tell you the quantity demanded, quantity supplied, and number of sellers for
					the products you are selling. For $1500 you can carry out comprehensive market research, which will
					tell you the quantity demanded, quantity supplied and number of sellers for all clothing products on
					the market.
				</Typography>
			</div>
		),
		Staffing: (
			<div>
				<Typography variant="body1">
					More staff will cost you more in wages. But will provide a better experience for your customers.
					Full time staff work 40 hours a week. Part time staff work 20 hours a week. To staff your shop with
					one person a day for a whole week, will require 60 hours, or one full time and one part time staff
					member.
				</Typography>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell align="right">Daily staffing</TableCell>
							<TableCell align="right">Minimum wage (monthly)</TableCell>
							<TableCell align="right">Living wage (monthly)</TableCell>
							<TableCell align="right">Weekly staff requirements</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{staffing.map((staff, i) => (
							<TableRow key={i}>
								<TableCell align="right" component="th" scope="row">
									{staff[0]}
								</TableCell>
								<TableCell align="right">{staff[1]}</TableCell>
								<TableCell align="right">{staff[2]}</TableCell>
								<TableCell align="right">{staff[3]}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				<Typography variant="body1">
					<b>Daily staffing</b> corresponds to ‘staff capacity’ on the locations table. The number of staff
					you will have working in the shop each day.
					<b>Minimum Wage (monthly)</b> is the monthly cost to you to meet your daily staffing requirement
					while paying a minimum wage to all employees.
					<b>Living Wage (monthly)</b> is the monthly cost to you to meet your daily staffing requirement
					while paying a living wage.
					<b>Weekly staff requirements</b> indicates how many staff you need to employ to correspond to that
					daily staffing level. A full time (40 hour) employee = 1. A part time employee (20 hours) = 0.5.
				</Typography>
			</div>
		),
	},
	["Accounting Glossary"]: {
		["Assets - Things you OWN"]: (
			<div>
				<Typography variant="body1">
					<b>Bank</b> is the cash / money that your business has available to spend. You will receive interest
					from the bank for the money you have in the bank at the start of each month.
					<br />
					<br />
					<b>Fixtures and Fittings</b> are the things that make your shop ready for use. They include shelves,
					racks, cash registers, counters, carpet, changing rooms, and staff facilities.
					<br />
					<br />
					<b>Bond</b> is money you paid to your landlord. You will get it back at the end of the year if you
					did not breach your tenancy agreement.
					<br />
					<br />
					<b>Inventory</b> is any unsold stock carried over from month to month.
				</Typography>
			</div>
		),
		["Liabilities - Things you OWE"]: (
			<div>
				<Typography variant="body1">
					<b>Loan</b> is money borrowed from the bank to help with setting up and running your business. The
					cost of a loan is that you will have to pay interest at the start of each month as a percentage of
					the principal of the loan (the amount you still have to pay back).
				</Typography>
			</div>
		),
		["Income - Money received due to work or investments"]: (
			<div>
				<Typography variant="body1">
					<b>Sales Revenue</b> is money received from customers in exchange for products that they buy from
					you. This will be your main source of income.
					<br />
					<br />
					<b>Interest Received</b> is money earned from cash assets (cash in your bank account).
				</Typography>
			</div>
		),
		["Expenses - Cost of operating the business"]: (
			<div>
				<Typography variant="body1">
					<b>Advertising</b> is the cost to advertise your business.
					<br />
					<br />
					<b>Rent</b> is the monthly cost that you pay to your landlord to use your property.
					<br />
					<br />
					<b>Office expenses</b> includes phone and internet bills, postage, software, and equipment.
					<br />
					<br />
					<b>Interest</b> is the cost of borrowing money from the bank. It is calculated at the start of each
					month as a percentage of the principal of your loan.
					<br />
					<br />
					<b>Market research</b> is the cost of gathering information about each months market activity.
					<br />
					<br />
					<b>Cost of goods sold (COGS)</b> is the total cost of all goods you have sold during the time
					period. Once a product is sold, it changes from an asset (inventory) to an expense (cost of goods
					sold).
				</Typography>
			</div>
		),
		["Capital / Owners Equity - The owners investment in the business"]: (
			<div>
				<Typography variant="body1">
					<b>Capital</b> is the amount of money you would have if you sold all your assets and paid off all
					your debts.
					<br />
					<br />
					<b>Drawings</b> is money taken out of the business by the owner.
					<br />
					<br />
					<b>Profit</b> is the difference between what the business earns, and what is spends to run.
				</Typography>
			</div>
		),
	},
};

export function HandbookSectionViewer(props) {
	const [open, setOpen] = React.useState(false);

	const handleClick = () => {
		setOpen(!open);
	};

	return (
		<div style={{ marginLeft: props.subsection ? "20px" : "0px" }}>
			<ListItem button onClick={handleClick}>
				<ListItemText primary={props.section} />
				{open ? <ExpandLess /> : <ExpandMore />}
			</ListItem>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<div style={{ marginLeft: "20px", marginRight: "10px" }}>{handbook[props.chapter][props.section]}</div>
			</Collapse>
		</div>
	);
}

export function HandbookChapterViewer(props) {
	const [open, setOpen] = React.useState(false);

	const handleClick = () => {
		setOpen(!open);
	};

	return (
		<div>
			<ListItem button onClick={handleClick}>
				<ListItemText primary={props.chapter} />
				{open ? <ExpandLess /> : <ExpandMore />}
			</ListItem>
			<Collapse in={open} timeout="auto" unmountOnExit>
				{Object.keys(handbook[props.chapter]).map((section) => (
					<HandbookSectionViewer subsection chapter={props.chapter} section={section} key={section} />
				))}
			</Collapse>
		</div>
	);
}

export default function RelatedHandbookSections(props) {
	return (
		<Paper style={{ paddingTop: "20px", paddingBottom: "10px", width: "100%" }}>
			<Typography style={{ marginLeft: "15px" }} variant="h6">
				Related handbook sections:
			</Typography>
			{props.related.map((section) => (
				<HandbookSectionViewer chapter={section.chapter} section={section.section} key={section.section} />
			))}
		</Paper>
	);
}

export function HandbookViewer() {
	const classes = useStyles();
	return (
		<Fade in timeout={500}>
			<div style={{ height: "100%", width: "100%" }} className={classes.classPage}>
				<Typography variant="h4">Handbook</Typography>
				<Typography variant="subtitle1">Wellington College Year 10 Commerce Market Simulation</Typography>
				<Paper className={classes.classSection}>
					{Object.keys(handbook).map((key) => (
						<HandbookChapterViewer chapter={key} key={key} />
					))}
				</Paper>
			</div>
		</Fade>
	);
}
